export const DeckChair = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M2.29289 7.79289C2.68342 7.40237 3.31658 7.40237 3.70711 7.79289L7.41421 11.5H19C19.3344 11.5 19.6466 11.6671 19.8321 11.9453L21.8321 14.9453C22.1384 15.4048 22.0142 16.0257 21.5547 16.3321C21.0952 16.6384 20.4743 16.5142 20.1679 16.0547L18.4648 13.5H7.53518L5.83205 16.0547C5.5257 16.5142 4.90483 16.6384 4.4453 16.3321C3.98577 16.0257 3.8616 15.4048 4.16795 14.9453L5.7132 12.6274L2.29289 9.20711C1.90237 8.81658 1.90237 8.18342 2.29289 7.79289Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
