import Cookies from 'js-cookie'
import { useEffect } from 'react'
import {
  generatePath,
  Outlet,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import {
  CHOOSE_PRODUCT_PATH,
  INSERT_PERIOD_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
} from '../app/router/paths'
import { DataLayer } from '../components/DataLayer'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Toast } from '../components/Toast'
import { useAppDispatch, useAppSelector } from '../hooks/store'
import { useApp } from '../hooks/useApp'
import { useCurrentPath } from '../hooks/useCurrentPath'
import { AppMode } from '../models/app'
import { appSlice } from '../store/appSlice'
import { cartSlice } from '../store/cartSlice'
import { licenseSlice } from '../store/licenseSlice'

export function Root() {
  useApp()
  const license = useAppSelector(licenseSlice.selectors.license)
  const licenseStatus = useAppSelector(licenseSlice.selectors.status)
  const mode = useAppSelector(appSlice.selectors.mode)
  const isRootPath = useMatch(ROOT_PATH)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { currentPath } = useCurrentPath()

  useEffect(() => {
    if (isRootPath && licenseStatus === 'success' && license) {
      navigate(
        `${generatePath(INSERT_PERIOD_PATH, { license: license.license })}?${searchParams.toString()}`
      )
    }
  }, [isRootPath, licenseStatus, license, navigate])

  useEffect(() => {
    // if kiosk mode, check if cookie setting is present,
    // otherwise redirect to the settings page
    if (
      mode === AppMode.KIOSK &&
      !Cookies.get(import.meta.env.VITE_KIOSK_SETTINGS_COOKIE)
    ) {
      navigate(`${SETTINGS_PATH}?ch=${AppMode.KIOSK}`)
    }
  }, [mode])

  useEffect(() => {
    if ([INSERT_PERIOD_PATH, CHOOSE_PRODUCT_PATH].includes(currentPath)) {
      dispatch(cartSlice.actions.reset())
    }
    // This resets the cart when a user leaves the map before purchase
  }, [
    currentPath,
    INSERT_PERIOD_PATH,
    CHOOSE_PRODUCT_PATH,
    cartSlice,
    dispatch,
  ])

  if (licenseStatus === 'idle' || licenseStatus === 'loading') {
    return <div>Loading...</div>
  }

  if (licenseStatus === 'error') {
    return <div>Oops! There was an error.</div>
  }

  return (
    <div className="flex min-h-full flex-col">
      <Header />

      <Outlet />

      <Footer />

      <Toast />

      <DataLayer />
    </div>
  )
}
