import { ItemQuantityController } from '../../../../components/ItemQuantityController/ItemQuantityController'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { useDayDifference } from '../../../../hooks/useDayDifference'
import { changeQuantityActioType } from '../../../../models/cart'
import { Service, ServiceLimitType } from '../../../../models/service'
import {
  cartChangeQuotableServiceQuantity,
  cartSlice,
} from '../../../../store/cartSlice'
import { mapSelectors } from '../../../../store/mapSlice'
import { getServiceMinimumQuantity } from '../../../../utils/getServiceMinimumQuantity'
import { getServiceUpdatedQuantity } from '../../../../utils/getServiceUpdatedQuantity'

interface Props {
  service: Service
}
export const SetupServiceListItem = (props: Props) => {
  const selectedElementQuotedSeatsAmount = useAppSelector(
    cartSlice.selectors.selectedElementQuotedSeatsAmount
  )
  const dayDifference = useDayDifference()
  const selectedElement = useAppSelector(mapSelectors.selectedElement)
  const cartReservations = useAppSelector(cartSlice.selectors.cartReservations)
  const currentElementCartReservation = cartReservations?.find(
    (reservation) => reservation.spotName === selectedElement?.name
  )
  const quoteReservations = useAppSelector(
    cartSlice.selectors.quoteReservations
  )
  const currentElementQuoteReservation = quoteReservations?.find(
    (reservation) => reservation.spotName === selectedElement?.name
  )
  const dispatch = useAppDispatch()

  const currentServiceKey = `id${props.service.serviceId}`

  const handleAddService = () => {
    dispatch(
      cartChangeQuotableServiceQuantity({
        actionType: changeQuantityActioType.ADD,
        dynamicKey: currentServiceKey,
      })
    )
  }

  const handleRemoveService = () => {
    dispatch(
      cartChangeQuotableServiceQuantity({
        actionType: changeQuantityActioType.REMOVE,
        dynamicKey: currentServiceKey,
      })
    )
  }

  const getTicketLimit = () => {
    if (props.service.maximumLimitType === ServiceLimitType.NUMBER_OF_PIECES) {
      return selectedElementQuotedSeatsAmount
    }

    if (props.service.maximumLimitType === ServiceLimitType.NUMBER_OF_DAYS) {
      return dayDifference
    }

    return props.service.maximumQuantity || props.service.availableQuantity
  }
  const ticketLimit = getTicketLimit()

  const dynamicKey = `id${props.service.serviceId}`

  const initialQuantity = getServiceUpdatedQuantity({
    dayDifference,
    selectedSeats: selectedElementQuotedSeatsAmount,
    service: props.service,
    serviceInCartQuantity: currentElementCartReservation?.services[dynamicKey],
    serviceInQuoteReservationQuantity:
      currentElementQuoteReservation?.services[dynamicKey],
  })

  const minimumQuantity = getServiceMinimumQuantity(
    props.service,
    selectedElementQuotedSeatsAmount,
    dayDifference
  )

  return (
    <ItemQuantityController
      available={{
        global: props.service.availableQuantity,
        group: props.service.availableQuantity,
        ticket: ticketLimit,
      }}
      hasLimit={props.service.hasLimit}
      icon={props.service.icon}
      initialQuantity={initialQuantity}
      key={props.service.serviceId}
      minimumQuantity={minimumQuantity}
      name={props.service.name}
      onAdd={handleAddService}
      onRemove={handleRemoveService}
      price={props.service.price}
    />
  )
}
