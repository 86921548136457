export const BasketBall = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2C10.3277 2.00171 8.6826 2.42276 7.21513 3.22463C5.74765 4.0265 4.5047 5.18359 3.6 6.59C2.32757 8.58555 1.80415 10.9677 2.12283 13.3129C2.44151 15.658 3.58163 17.8142 5.34043 19.3978C7.09924 20.9815 9.36272 21.89 11.7283 21.9618C14.094 22.0336 16.4084 21.264 18.26 19.79C19.8715 18.4929 21.0411 16.7278 21.6076 14.7382C22.174 12.7485 22.1095 10.6321 21.4228 8.68067C20.7361 6.72923 19.4611 5.03878 17.7735 3.84228C16.0859 2.64578 14.0687 2.00212 12 2ZM14 4.26C15.3818 4.61769 16.6428 5.33858 17.6521 6.34789C18.6614 7.3572 19.3823 8.61817 19.74 10C18.068 10.0471 16.436 10.5222 15 11.38C14.2789 10.562 13.4746 9.82132 12.6 9.17C13.4811 7.68096 13.9633 5.98983 14 4.26ZM12 4C11.9967 5.43503 11.6026 6.84205 10.86 8.07C10.71 7.99 10.57 7.9 10.42 7.83C9.13237 7.15059 7.75554 6.65574 6.33 6.36C7.07305 5.61252 7.95647 5.01923 8.92952 4.61423C9.90256 4.20922 10.946 4.00048 12 4ZM5 8.16C6.56842 8.37788 8.08742 8.86504 9.49 9.6L9.62 9.68C8.88192 10.4174 8.00557 11.0019 7.04118 11.4C6.0768 11.7981 5.04333 12.002 4 12C4.00557 10.6563 4.34948 9.33571 5 8.16ZM10 19.74C8.61817 19.3823 7.3572 18.6614 6.34789 17.6521C5.33858 16.6428 4.6177 15.3818 4.26 14C5.59566 13.9703 6.91149 13.6707 8.12825 13.119C9.34502 12.5673 10.4375 11.7751 11.34 10.79C12.0629 11.3244 12.7327 11.9272 13.34 12.59C12.3274 13.4929 11.5091 14.5924 10.9349 15.8216C10.3607 17.0508 10.0426 18.384 10 19.74ZM12 20C12.0022 18.9014 12.2306 17.815 12.671 16.8085C13.1115 15.802 13.7545 14.8971 14.56 14.15C14.62 14.23 14.68 14.3 14.73 14.38C15.568 15.6632 16.1896 17.0753 16.57 18.56C15.2316 19.4997 13.6354 20.0027 12 20ZM18.21 17C17.7757 15.677 17.1706 14.4163 16.41 13.25L16.2 13C17.3627 12.3532 18.6695 12.0093 20 12C19.9901 13.8219 19.3586 15.5858 18.21 17Z"
        fill="currentColor"
      />
    </svg>
  )
}
