import { Alert } from '@spiaggeit/spit-ui'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { ExclamationMark } from '../../assets/icons/ExclamationMark'
import { useAppSelector } from '../../hooks/store'
import { BookingAvailabilityError } from '../../models/bookingAvailability'
import { bookingAvailabilitySlice } from '../../store/bookingAvailabilitySlice'
import { licenseSlice } from '../../store/licenseSlice'

interface Props {
  isSubmitted: boolean
}

//E. la logica di queste alert è da rifare, infatti la doc dice:
// "vi sono definiti delle diciture che rappresentano i vari errore che l’utente può riscontrare durante
// la navigazione, anche in questo punto sarebbe da non passarli più in queryString tra una rotta e l’altra."
export function InsertPeriodAlerts(props: Props) {
  const { t, i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  const license = useAppSelector(licenseSlice.selectors.license)
  const isBookingEnabled = useAppSelector(
    licenseSlice.selectors.isBookingEnabled
  )
  const seasonStart = useAppSelector(licenseSlice.selectors.seasonStart)
  const seasonEnd = useAppSelector(licenseSlice.selectors.seasonEnd)
  const bookingAvailabilityErrorCode = useAppSelector(
    bookingAvailabilitySlice.selectors.errorCode
  )
  const bookingAvailabilityErrorSubstitutions = useAppSelector(
    bookingAvailabilitySlice.selectors.errorSubstitutions
  )
  const hasError =
    (props.isSubmitted && bookingAvailabilityErrorCode !== null) ||
    searchParams.has('ed') ||
    searchParams.has('ewd') ||
    searchParams.has('ewdo') ||
    searchParams.has('ef') ||
    searchParams.has('ewd05') ||
    searchParams.has('edmin') ||
    searchParams.has('edmax') ||
    searchParams.has('envl') ||
    searchParams.has('ebttf') ||
    searchParams.has('ebtl') ||
    searchParams.has('error')

  if (!license || !hasError) {
    return null
  }

  if (!isBookingEnabled) {
    return (
      <AlertWrapper
        intent="critical"
        message={t('insertPeriod.alerts.bookingDisabled')}
      />
    )
  }

  if (
    bookingAvailabilityErrorCode ===
    BookingAvailabilityError.BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_001
  ) {
    return (
      <AlertWrapper
        intent="warning"
        message={t('insertPeriod.alerts.001', {
          from: bookingAvailabilityErrorSubstitutions?.booking_start_date,
          to: bookingAvailabilityErrorSubstitutions?.booking_end_date,
        })}
      />
    )
  }

  if (
    bookingAvailabilityErrorCode ===
    BookingAvailabilityError.BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_002
  ) {
    return (
      <AlertWrapper
        intent="warning"
        message={t('insertPeriod.alerts.002', {
          timeLimit: bookingAvailabilityErrorSubstitutions?.time_limit,
        })}
      />
    )
  }

  if (
    bookingAvailabilityErrorCode ===
    BookingAvailabilityError.BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_003
  ) {
    return (
      <AlertWrapper
        intent="warning"
        message={t('insertPeriod.alerts.003', {
          offset: bookingAvailabilityErrorSubstitutions?.offset,
        })}
      />
    )
  }

  if (
    bookingAvailabilityErrorCode ===
    BookingAvailabilityError.BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_004
  ) {
    return (
      <AlertWrapper intent="warning" message={t('insertPeriod.alerts.004')} />
    )
  }

  if (
    bookingAvailabilityErrorCode ===
    BookingAvailabilityError.BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_005
  ) {
    return (
      <AlertWrapper
        intent="warning"
        message={t('insertPeriod.alerts.005', {
          minDays: bookingAvailabilityErrorSubstitutions?.min_days,
        })}
      />
    )
  }

  if (
    bookingAvailabilityErrorCode ===
    BookingAvailabilityError.BOOKING_AVAILABILITY_MANAGER_EXCEPTION_ERROR_006
  ) {
    return (
      <AlertWrapper
        intent="warning"
        message={t('insertPeriod.alerts.006', {
          maxDays: bookingAvailabilityErrorSubstitutions?.max_days,
        })}
      />
    )
  }

  if (
    bookingAvailabilityErrorCode ===
    BookingAvailabilityError.TICKET_OVERBOOKING_EXCEPTION_ERROR_001
  ) {
    return (
      <AlertWrapper
        intent="warning"
        message={t('insertPeriod.alerts.TICKETS_OVERBOOKING_001')}
      />
    )
  }

  if (bookingAvailabilityErrorCode === BookingAvailabilityError.SOLD_OUT) {
    return (
      <AlertWrapper
        intent="warning"
        message={t('insertPeriod.alerts.SOLD_OUT')}
      />
    )
  }

  if (searchParams.has('ed')) {
    if (!license.bookingToday) {
      const msgSuffix =
        typeof license.bookingTimeLimit === 'string'
          ? `${t('insertPeriod.untilHours', { hours: license.bookingTimeLimit })}`
          : `${t('insertPeriod.untilMidnight')}`

      const msg = `${t('insertPeriod.bookUntil')}${msgSuffix}`
      return <AlertWrapper intent="critical" message={msg} />
    } else {
      return (
        <AlertWrapper
          intent="critical"
          message={t('insertPeriod.wrongDates')}
        />
      )
    }
  }

  if (
    searchParams.has('ewd') &&
    typeof seasonStart === 'string' &&
    typeof seasonEnd === 'string'
  ) {
    const start = DateTime.fromFormat(seasonStart, 'dd-LL-yyyy').toJSDate()
    const end = DateTime.fromFormat(seasonEnd, 'dd-LL-yyyy').toJSDate()

    const formatter = Intl.DateTimeFormat(i18n.language, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })

    return (
      <AlertWrapper
        intent="critical"
        message={t('insertPeriod.alerts.001', {
          from: formatter.format(start),
          to: formatter.format(end),
        })}
      />
    )
  }

  if (
    searchParams.has('ewdo') &&
    typeof license.bookingEndPeriodOffset === 'number'
  ) {
    return (
      <AlertWrapper
        intent="critical"
        message={t('insertPeriod.alerts.003', {
          offset: license.bookingEndPeriodOffset,
        })}
      />
    )
  }

  if (searchParams.has('ef')) {
    return (
      <AlertWrapper
        intent="critical"
        message={t('insertPeriod.alerts.errorEf')}
      />
    )
  }

  if (searchParams.has('ewd05')) {
    return (
      <AlertWrapper
        intent="critical"
        message={t('insertPeriod.alerts.005', { minDays: 5 })}
      />
    )
  }

  if (searchParams.has('edmin')) {
    return (
      <AlertWrapper
        intent="critical"
        message={t('insertPeriod.alerts.005', {
          minDays: searchParams.get('edmin'),
        })}
      />
    )
  }

  if (searchParams.has('edmax')) {
    const edmax = searchParams.get('edmax')
    if (edmax === '0') {
      return (
        <AlertWrapper
          intent="critical"
          message={t('insertPeriod.alerts.004')}
        />
      )
    } else {
      return (
        <AlertWrapper
          intent="critical"
          message={t('insertPeriod.alerts.006', {
            maxDays: edmax,
          })}
        />
      )
    }
  }

  if (searchParams.has('envl')) {
    return (
      <AlertWrapper
        intent="critical"
        message={t('insertPeriod.alerts.errorEnvl')}
      />
    )
  }

  if (searchParams.has('ebttf')) {
    return (
      <AlertWrapper
        intent="critical"
        message={t('insertPeriod.alerts.errorEbttf')}
      />
    )
  }

  if (searchParams.has('ebtl')) {
    return (
      <AlertWrapper
        intent="critical"
        message={t('insertPeriod.alerts.002', {
          timeLimit: license.bookingTimeLimit ?? '16:00',
        })}
      />
    )
  }

  const error = searchParams.get('error')
  if (typeof error === 'string') {
    switch (error) {
      case 'i_r_001':
        return (
          <AlertWrapper
            intent="critical"
            message={t('insertPeriod.alerts.reservationBooked')}
          />
        )

      case 'i_booking_003':
        return (
          <AlertWrapper
            intent="critical"
            message={t('insertPeriod.alerts.cartExpired')}
          />
        )

      default:
        return (
          <AlertWrapper
            intent="critical"
            message={t('insertPeriod.alerts.genericError', { errorId: error })}
          />
        )
    }
  }

  return (
    <AlertWrapper
      intent="critical"
      message={t('insertPeriod.alerts.genericError')}
    />
  )
}

function AlertWrapper(props: {
  intent: 'neutral' | 'success' | 'warning' | 'critical' | 'danger'
  message: string
}) {
  return (
    <Alert className="container mb-4 w-full sm:max-w-3xl" intent={props.intent}>
      <ExclamationMark className="text-yellow-600" height={24} width={24} />
      {props.message}
    </Alert>
  )
}
