export const Minus = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6667 7.33337H3.33341C3.1566 7.33337 2.98703 7.40361 2.86201 7.52864C2.73699 7.65366 2.66675 7.82323 2.66675 8.00004C2.66675 8.17685 2.73699 8.34642 2.86201 8.47145C2.98703 8.59647 3.1566 8.66671 3.33341 8.66671H12.6667C12.8436 8.66671 13.0131 8.59647 13.1382 8.47145C13.2632 8.34642 13.3334 8.17685 13.3334 8.00004C13.3334 7.82323 13.2632 7.65366 13.1382 7.52864C13.0131 7.40361 12.8436 7.33337 12.6667 7.33337Z"
      fill="currentColor"
    />
  </svg>
)
