export const Sunbeds = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.70711 6.54289C7.31658 6.15237 6.68342 6.15237 6.29289 6.54289C5.90237 6.93342 5.90237 7.56658 6.29289 7.95711L9.7132 11.3774L8.79815 12.75H7.41421L3.70711 9.04289C3.31658 8.65237 2.68342 8.65237 2.29289 9.04289C1.90237 9.43342 1.90237 10.0666 2.29289 10.4571L5.7132 13.8774L4.16795 16.1953C3.8616 16.6548 3.98577 17.2757 4.4453 17.5821C4.90483 17.8884 5.5257 17.7642 5.83205 17.3047L7.53518 14.75H8.13372C8.20818 14.8792 8.3128 14.9937 8.4453 15.0821C8.90483 15.3884 9.5257 15.2642 9.83205 14.8047L9.86852 14.75H14.4648L16.1679 17.3047C16.4743 17.7642 17.0952 17.8884 17.5547 17.5821C18.0142 17.2757 18.1384 16.6548 17.8321 16.1953L15.8321 13.1953C15.6466 12.9171 15.3344 12.75 15 12.75H11.2019L11.5352 12.25H18.4648L20.1679 14.8047C20.4743 15.2642 21.0952 15.3884 21.5547 15.0821C22.0142 14.7757 22.1384 14.1548 21.8321 13.6953L19.8321 10.6953C19.6466 10.4171 19.3344 10.25 19 10.25H11.4142L7.70711 6.54289Z"
        fill="currentColor"
      />
    </svg>
  )
}
