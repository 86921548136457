import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Edit } from '../../../assets/icons/Edit'
import { Trash } from '../../../assets/icons/Trash'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { MapElementWithSectorId } from '../../../models/map'
import {
  cartMapSpotsSelector,
  cartSlice,
  QuoteReservation,
} from '../../../store/cartSlice'
import { mapSelectors, mapSlice } from '../../../store/mapSlice'
import { cartSetupElements } from '../../../utils/constants'
import { priceFormatter } from '../../../utils/price'
import { RemoveElementFromCartDialog } from '../RemoveElementFromCartDialog'

export interface Props {
  setPopoverIsOpen?: Dispatch<SetStateAction<boolean>>
}

export const CartContent = (props: Props) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const cartReservations = useAppSelector(cartSlice.selectors.cartReservations)
  const total = useAppSelector(cartSlice.selectors.total)
  const fees = useAppSelector(cartSlice.selectors.fees)
  const sectors = useAppSelector(mapSelectors.sectors)
  const reservationPrices = useAppSelector(
    cartSlice.selectors.reservationPrices
  )
  const quote = useAppSelector(cartSlice.selectors.quote)
  const mapSpots = useAppSelector(cartMapSpotsSelector)

  const { t } = useTranslation()

  const onDeleteItem = () => {
    setDialogIsOpen(true)
  }
  const dispatch = useAppDispatch()

  const onEditSpotClick = (spot: MapElementWithSectorId) => {
    dispatch(mapSlice.actions.selectMapElement(spot))
    props.setPopoverIsOpen && props.setPopoverIsOpen(false)
  }

  if (!mapSpots) return null
  return (
    <div>
      {mapSpots.map((spot) => {
        const sectorImage = sectors.find(
          (sector) => sector.header.id === spot.sectorId
        )?.header.image
        const quoteReservation = cartReservations.find(
          (item) => item.spotName === spot.name
        )
        return (
          <div
            className="border-b-neutral-100 flex gap-3 border-b py-4"
            key={spot.id}
          >
            {sectorImage && (
              <img className="h-16 w-16 rounded-md" src={sectorImage} />
            )}
            <div className="flex grow items-start gap-5">
              <div className="flex grow flex-col gap-1">
                <span className="bold text-lg font-bold text-primary">
                  {t('map.elementDetail.setup')} {spot.name}
                </span>
                {cartSetupElements.map((element) => {
                  const reservationValue =
                    quoteReservation?.[
                      element.quoteReservationKey as keyof QuoteReservation
                    ]

                  return reservationValue &&
                    typeof reservationValue === 'number'
                    ? (
                        <span
                          className="text-sm text-secondary"
                          key={element.quoteReservationKey}
                        >
                          {t(element.label)}: {reservationValue}
                        </span>
                      )
                    : null
                })}
              </div>
              <div className="flex h-full flex-col items-end justify-between">
                <div className="flex gap-5">
                  <button
                    className="text-gray-500"
                    onClick={() => onEditSpotClick(spot)}
                    type="button"
                  >
                    <Edit />
                  </button>
                  <button
                    className="text-gray-500"
                    onClick={onDeleteItem}
                    type="button"
                  >
                    <Trash />
                  </button>
                </div>
                {reservationPrices && (
                  <span className="text-secondary">
                    {priceFormatter.format(
                      reservationPrices[`_${spot.subType}_${spot.name}`]
                    )}
                  </span>
                )}
              </div>
            </div>
            <RemoveElementFromCartDialog
              element={spot}
              isOpen={dialogIsOpen}
              setIsOpen={setDialogIsOpen}
            />
          </div>
        )
      })}
      <div className="py-4">
        <div className="flex justify-between">
          <span className="text-sm font-medium text-secondary">
            {t('map.elementDetail.setup')}
          </span>
          <span className="text-sm text-secondary">
            {priceFormatter.format(total - fees)}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm font-medium text-secondary">
            {t('map.elementDetail.serviceCost.title')}
          </span>
          <span className="text-sm text-secondary">
            {priceFormatter.format(fees)}
          </span>
        </div>
        {quote?.offer && quote?.offer > 0
          ? (
              <div className="flex justify-between">
                <span className="text-sm font-medium text-secondary">
                  {t('map.elementDetail.offer')}
                </span>
                <span className="text-sm text-secondary">
              -{priceFormatter.format(quote.offer)}
                </span>
              </div>
            )
          : null}
      </div>
    </div>
  )
}
