import { DateTime } from 'luxon'

import { insertPeriodSlice } from '../store/insertPeriodSlice'

import { useAppSelector } from './store'

export function useDayDifference() {
  const period = useAppSelector(insertPeriodSlice.selectors.period)
  const startDate = DateTime.fromISO(period?.start || '')
  const endDate = DateTime.fromISO(period?.end || '')

  if (!startDate || !endDate) return 0

  const dayDifference = endDate.diff(startDate, 'days').days + 1

  return dayDifference
}
