import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'

import { Close } from '../../../assets/icons/Close'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { useDayDifference } from '../../../hooks/useDayDifference'
import { QuoteSetupItems } from '../../../models/cart'
import { MapSetupItemKeyName } from '../../../models/map'
import {
  cartAddReservationToQuote,
  cartSlice,
  cartSliceInitialState,
  QuoteReservation,
} from '../../../store/cartSlice'
import {
  mapCurrentSectorDetailsSelector,
  mapSelectedElementSetupDataSelector,
  mapSelectors,
  mapSlice,
} from '../../../store/mapSlice'
import { servicesSlice } from '../../../store/servicesSlice'
import { mapSetupElements } from '../../../utils/constants'
import { getServiceUpdatedQuantity } from '../../../utils/getServiceUpdatedQuantity'
import { SetupProductsList } from '../SetupProductsList'

import { DiscardChangesDialog } from './DiscardChangesDialog'
import { SelectedElementHeader } from './SelectedElementHeader'
import { SetupCartActions } from './SetupCartActions'

export const ElementDetail = () => {
  const dispatch = useAppDispatch()
  const dayDifference = useDayDifference()
  const selectedElement = useAppSelector(mapSelectors.selectedElement)

  const sectorDetails = useAppSelector(mapCurrentSectorDetailsSelector)

  const cartItems = useAppSelector(cartSlice.selectors.items)

  useEffect(() => {
    dispatch(servicesSlice.actions.load())
  }, [dispatch])

  const setupData = useAppSelector(mapSelectedElementSetupDataSelector)

  const selectedElementSetupItems = setupData?.selectedElementSetupItems

  const isElementAddedToCart = useMemo(() => {
    return Object.keys(cartItems).includes(`${selectedElement?.id}`)
  }, [cartItems, selectedElement])

  const cartReservations = useAppSelector(cartSlice.selectors.cartReservations)

  const servicesData = useAppSelector(servicesSlice.selectors.data)
  const includedServices = servicesData?.includedServices
  const extraServices = servicesData?.extraServices

  const selectedElementSeats = [
    MapSetupItemKeyName.BED,
    MapSetupItemKeyName.CHAIR,
    MapSetupItemKeyName.DECK_CHAIR,
    MapSetupItemKeyName.MAXI_BED,
  ].reduce<Omit<QuoteSetupItems, 'services'>>(
    (acc, key) => {
      const dynamicKey = mapSetupElements[key].quoteReservationKey
      const cartReservationElement = cartReservations.find(
        (item) => item?.spotName === selectedElement?.name
      )
      const dynamicValue =
        cartReservationElement &&
        cartReservationElement[dynamicKey as keyof QuoteReservation]

      return {
        ...acc,
        [key]:
          isElementAddedToCart && dynamicValue
            ? dynamicValue
            : selectedElementSetupItems?.find((item) => item.keyName === key)
              ?.d || 0,
      }
    },
    { b: 0, c: 0, d: 0, m: 0 }
  )

  const selectedElementQuotedSeatsAmount = useAppSelector(
    cartSlice.selectors.selectedElementQuotedSeatsAmount
  )

  const selectedElementServices =
    (includedServices &&
      extraServices &&
      [...includedServices, ...extraServices]?.reduce(
        (acc, service) => {
          const dynamicKey = `id${service?.serviceId}`
          const serviceInCartQuantity = cartReservations.find(
            (item) => item?.spotName === selectedElement?.name
          )?.services[dynamicKey]
          acc[dynamicKey] = getServiceUpdatedQuantity({
            dayDifference,
            selectedSeats: selectedElementQuotedSeatsAmount,
            service,
            serviceInCartQuantity,
          })
          return acc
        },
        {} as Record<string, number>
      )) ||
    {}

  const initialQuoteSetupItems = {
    ...selectedElementSeats,
    services: selectedElementServices,
  }

  useEffect(() => {
    dispatch(
      cartSlice.actions.setSelectedElementQuotableProducts(
        initialQuoteSetupItems
      )
    )
  }, [])

  const selectedElementQuotableProducts = useAppSelector(
    cartSlice.selectors.selectedElementQuotableProducts
  )

  useEffect(() => {
    if (selectedElementQuotedSeatsAmount < 1) return
    dispatch(cartAddReservationToQuote())
    dispatch(cartSlice.actions.quote())
  }, [selectedElementQuotableProducts, selectedElementQuotedSeatsAmount])

  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] =
    useState(false)

  const handleClose = () => {
    dispatch(mapSlice.actions.selectMapElement(null))
    dispatch(
      cartSlice.actions.setSelectedElementQuotableProducts(
        cartSliceInitialState.selectedElementQuotableProducts
      )
    )
  }

  const onCloseButtonClick = () => {
    if (!selectedElement) return
    if (isElementAddedToCart) {
      setIsDiscardChangesDialogOpen(true)
    } else {
      handleClose()
      dispatch(cartSlice.actions.removeQuoteReservations(selectedElement))
    }
  }

  if (!selectedElement || !sectorDetails || !selectedElementSetupItems)
    return null

  return (
    <div className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-black/60">
      <div className="absolute bottom-0 left-0 right-0 top-20 flex grow flex-col gap-3 rounded-t-lg bg-white lg:left-auto lg:top-0 lg:max-h-full lg:min-w-96 lg:max-w-sm lg:rounded-none">
        <button
          aria-label={t('common.closeImperativeVerb')}
          className="absolute right-4 ml-auto mt-4 bg-white"
          onClick={onCloseButtonClick}
        >
          <Close />
        </button>
        <DiscardChangesDialog
          isOpen={isDiscardChangesDialogOpen}
          setIsOpen={setIsDiscardChangesDialogOpen}
        />
        <div className="overflow-y-scroll">
          <SelectedElementHeader />
          <SetupProductsList />
        </div>
        <SetupCartActions handleClose={handleClose} />
      </div>
    </div>
  )
}
