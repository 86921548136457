export interface KioskPosTerminalResponse {
  result: {
    paymentIntent: {
      stripeIntent: {
        id: string
      }
      stripeTerminalProcessIntent: null | unknown
    }
  }
}

export enum KioskPosTerminalError {
  PAYMENT_INTENT_EXCEPTION_ERROR_001 = 'PAYMENT_INTENT_EXCEPTION_ERROR_001',
}

export interface KioskPosTerminalResponseError {
  internal_error_code?: KioskPosTerminalError | null
}

export interface KioskSettingsResponse {
  result: {
    kioskSettings: {
      posTerminalId: string
      printer: {
        id: number
        name: string
        model: number
        remote: boolean
        ip: string
        port: string
        ssl: boolean
        mode: number
        invoiceWidth: number
        invoiceTitle: string
        invoiceSubtitle: string
        invoiceFooter: string
        printMode: {
          fis: number
          list: number
          nfis: number
          text: number
        }
        departments: number[]
        createdAt: null
        updatedAt: null
        registrationNumber: string
        idPrinter: number
        header: string
        header2: string
        footer: string
      }
    }
  }
}

export interface PaymentIntentStatusResponse {
  result: {
    completed: number
  }
}
export interface AbortPaymentIntentResponse {
  result: {
    canceled: boolean
  }
}

export interface CartResponse {
  result: {
    cart: {
      reservationModels: Reservation[]
    }
  }
}

export interface ServicesResponse {
  result: {
    beachServices: Record<number, Service>
  }
}

export interface Reservation {
  id: number
  discount: number | null
  spot_name: string
  sector: {
    name: string
  } | null
  type: ReservationType
  maxi_beds: number
  beds: number
  chairs: number
  deck_chairs: number
  start_date: number
  end_date: number
  spot_type: SpotType
  qrCode: string
  forced_total: number
  forced_beach: number
  services: ReservationService[]
}

export interface ReservationService {
  service_id: number
  bought: number
  used: number | null
  deleted: number | null
  price: number
  forced_price: number | null
  paid: number
}

export interface Service {
  id: number
  name: string
  price: number
  vat: number
}

export enum ReservationType {
  STANDARD = 1,
  MOVING,
  ABSENCE,
  ADDITION,
  JOINT_ACCOUNT,
  BILL,
}

export enum SpotType {
  UMBRELLA = 'umbrella',
  GAZEBO = 'gazebo',
  PARKING = 'parking',
  BED = 'bed',
  BEDS = 'beds',
  CABIN = 'cabins',
  BOAT = 'boat',
  PLAYFIELD = 'playfield',
}

export interface PrintArticle {
  description: string
  quantity: number
  price: number
  vat: number
}

export interface QuoteSetupItems {
  b: number
  c: number
  d: number
  m: number
  services: Record<string, number>
}

export enum changeQuantityActioType {
  ADD = 'add',
  REMOVE = 'remove',
}
