import { createAppSlice } from './createAppSlice'

type SliceState = {
  message?: string
  isOpen: boolean
  ctaCopy?: string
}

const initialState: SliceState = {
  isOpen: false,
}

export const toastSlice = createAppSlice({
  initialState: initialState satisfies SliceState as SliceState,
  name: 'toast',
  reducers: (create) => ({
    setIsOpen: create.reducer<boolean>((state, action) => ({
      ...state,
      isOpen: action.payload,
    })),
    show: create.reducer<{ message: string; ctaCopy?: string }>(
      (_, action) => ({
        ctaCopy: action.payload?.ctaCopy,
        isOpen: true,
        message: action.payload.message,
      })
    ),
  }),
  selectors: {
    ctaCopy: (state) => state.ctaCopy,
    isOpen: (state) => state.isOpen,
    message: (state) => state.message,
  },
})
