import { cn, Dialog } from '@spiaggeit/spit-ui'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import {
  INSERT_PERIOD_PATH,
  LANDING_KIOSK_PATH,
  PAYMENT_KIOSK_PATH,
} from '../../app/router/paths'
import { ArrowLeft } from '../../assets/icons/ArrowLeft'
import { Info } from '../../assets/icons/Info'
import { useAppSelector } from '../../hooks/store'
import { useBackLink } from '../../hooks/useBackLink'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { AppMode } from '../../models/app'
import { appSlice } from '../../store/appSlice'
import { licenseSlice } from '../../store/licenseSlice'

import { InsertPeriodLink } from './InsertPeriodLink'
import { ProgressBar } from './ProgressBar'
import { TopBar } from './TopBar'

export const Header = () => {
  const license = useAppSelector(licenseSlice.selectors.license)
  const beachLocation = useAppSelector(licenseSlice.selectors.beachLocation)
  const appMode = useAppSelector(appSlice.selectors.mode)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { currentPath } = useCurrentPath()
  const backLink = useBackLink()

  if (
    !license ||
    [LANDING_KIOSK_PATH, PAYMENT_KIOSK_PATH].includes(currentPath)
  ) {
    return null
  }

  return (
    <>
      <header
        className={cn('sticky top-0 z-10 bg-white', {
          'lg:shadow-md':
            currentPath === INSERT_PERIOD_PATH && appMode !== AppMode.WIDGET,
          'shadow-md': currentPath !== INSERT_PERIOD_PATH,
        })}
      >
        <TopBar />

        <div className="mx-auto max-w-screen-xl space-x-3 px-4 py-3 md:flex lg:mx-32">
          <div className="flex w-full flex-auto items-center justify-between space-x-12 md:w-auto md:justify-start">
            <div className="flex flex-initial space-x-3">
              {backLink
                ? (
                    <NavLink to={backLink}>
                      <ArrowLeft className="h-8 w-8" />
                    </NavLink>
                  )
                : null}

              <div className="max-w-60">
                <div
                  className="flex cursor-pointer space-x-2 "
                  onClick={() => setIsDialogOpen(true)}
                >
                  <div
                    className="overflow-hidden text-ellipsis text-nowrap font-bold leading-tight text-primary"
                    title={license.name}
                  >
                    {license.name}
                  </div>

                  <Info className="text-primary" />
                </div>

                <div
                  className="overflow-hidden text-ellipsis text-sm text-secondary"
                  title={beachLocation || ''}
                >
                  {beachLocation}
                </div>
              </div>
            </div>

            <InsertPeriodLink />
          </div>

          <div
            className={cn('mt-2 flex-auto md:mt-0', {
              'hidden lg:block': currentPath === INSERT_PERIOD_PATH,
            })}
          >
            <ProgressBar />
          </div>
        </div>
      </header>

      <Dialog
        isOpen={isDialogOpen}
        mobileMode="bottomSheet"
        setIsOpen={setIsDialogOpen}
        title={license.name}
        trigger={null}
      >
        <div className="p-4">{license.bookingMapNotes}</div>
      </Dialog>
    </>
  )
}
