import { cn } from '@spiaggeit/spit-ui'
import { matchPath, useLocation } from 'react-router-dom'

interface Props {
  href: string
  label: string
}

export const Step = (props: Props) => {
  const location = useLocation()

  const isActive = matchPath(props.href, location.pathname) !== null

  return (
    <div className="flex-0 flex flex-col items-center">
      {isActive
        ? (
            <div className="flex-0 rounded-circle border border-yellow-300 p-1">
              <div className="size-2 rounded-circle bg-yellow-300 md:size-3" />
            </div>
          )
        : (
            <div className="flex-0 border-secondary size-4 rounded-circle border md:size-5" />
          )}

      <div
        className={cn(
          'absolute translate-y-6 pt-1 text-xs text-secondary md:translate-y-full',
          { 'font-bold': isActive }
        )}
      >
        {props.label}
      </div>
    </div>
  )
}
