export const Cabin = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M11.417 2.35431C12.0588 1.8819 12.9412 1.8819 13.583 2.35431L18.9922 6.33599H19.055V6.38221L20.613 7.52909C20.9908 7.80716 21.0674 8.33314 20.784 8.7039C20.5007 9.07466 19.9648 9.1498 19.587 8.87173L19.055 8.48009V20.3217H21.145C21.6172 20.3217 22 20.6974 22 21.1608C22 21.6243 21.6172 22 21.145 22L3.855 22C3.3828 22 3 21.6243 3 21.1608C3 20.6974 3.3828 20.3217 3.855 20.3217H5.94498V8.48016L5.41303 8.87173C5.03526 9.1498 4.49935 9.07466 4.21603 8.7039C3.93271 8.33314 4.00926 7.80716 4.38703 7.52909L5.94498 6.38228V6.33599H6.00787L11.417 2.35431ZM7.65498 20.3217H8.79502V20.1352H7.655L7.65498 20.3217ZM7.65498 18.4569V17.338H8.795L8.79502 18.4569H7.65498ZM8.79502 15.6597V14.5409H7.655L7.65498 15.6597H8.79502ZM7.65498 12.8626V11.7437H8.795L8.79502 12.8626H7.65498ZM8.795 10.0654C8.88125 10.0654 8.96451 10.0779 9.04301 10.1012C9.35666 9.57776 9.93654 9.22641 10.6 9.22641L14.4 9.22641C15.0635 9.22641 15.6434 9.57775 15.957 10.1012C16.0355 10.0779 16.1188 10.0654 16.205 10.0654H17.345V8.94651H7.655L7.65498 10.0654H8.795ZM16.205 12.8626V11.7437H17.345V12.8626H16.205ZM16.205 14.5409V15.6597H17.345V14.5409H16.205ZM16.205 18.4569V17.338H17.345V18.4569H16.205ZM16.205 20.1352V20.3217H17.345V20.1352H16.205ZM7.655 7.2682H17.345V7.22136L15.7913 6.07767C15.6854 6.12373 15.5682 6.14933 15.445 6.14933H9.555C9.43177 6.14933 9.31462 6.12374 9.20878 6.07768L7.65498 7.22143L7.655 7.2682ZM11.3914 4.47102H13.6086L12.557 3.69695C12.5232 3.67209 12.4768 3.67209 12.443 3.69695L11.3914 4.47102ZM14.495 20.3217L14.495 10.998C14.495 10.9465 14.4525 10.9047 14.4 10.9047L10.6 10.9047C10.5476 10.9047 10.505 10.9465 10.505 10.9979L10.505 20.3217L14.495 20.3217Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
