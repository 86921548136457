import { Dialog } from '@spiaggeit/spit-ui'
import { t } from 'i18next'

import { ExclamationMark } from '../../../assets/icons/ExclamationMark'
import { useAppSelector } from '../../../hooks/store'
import { mapCurrentSectorSelector, mapSelectors } from '../../../store/mapSlice'

import { RefundPolicy } from './RefundPolicy'

export const SelectedElementHeader = () => {
  const selectedElement = useAppSelector(mapSelectors.selectedElement)
  const currentSector = useAppSelector(mapCurrentSectorSelector)

  return (
    <div className="flex items-stretch justify-between gap-2 border-b border-solid border-gray-200 p-4">
      {currentSector?.header.image && (
        <img
          className="w-20 rounded-md object-cover"
          src={currentSector.header.image}
        />
      )}
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <h3 className="font-bold capitalize text-primary">
            {selectedElement?.subType} {selectedElement?.name}
          </h3>
        </div>
        {currentSector?.header.description && (
          <Dialog
            mobileMode="bottomSheet"
            scrollContent
            size="sm"
            title={t('map.elementDetail.info')}
            trigger={
              <button className="flex cursor-pointer items-center gap-1 text-sm text-secondary underline">
                {t('map.elementDetail.info')}
                <ExclamationMark height={24} width={24} />
              </button>
            }
          >
            <p className="p-4 text-secondary">
              {currentSector?.header.description}
            </p>
          </Dialog>
        )}
        <RefundPolicy />
      </div>
    </div>
  )
}
