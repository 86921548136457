export const Plus = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6667 7.33329H8.66675V3.33329C8.66675 3.15648 8.59651 2.98691 8.47149 2.86189C8.34646 2.73686 8.17689 2.66663 8.00008 2.66663C7.82327 2.66663 7.6537 2.73686 7.52868 2.86189C7.40365 2.98691 7.33341 3.15648 7.33341 3.33329V7.33329H3.33341C3.1566 7.33329 2.98703 7.40353 2.86201 7.52855C2.73699 7.65358 2.66675 7.82315 2.66675 7.99996C2.66675 8.17677 2.73699 8.34634 2.86201 8.47136C2.98703 8.59639 3.1566 8.66663 3.33341 8.66663H7.33341V12.6666C7.33341 12.8434 7.40365 13.013 7.52868 13.138C7.6537 13.2631 7.82327 13.3333 8.00008 13.3333C8.17689 13.3333 8.34646 13.2631 8.47149 13.138C8.59651 13.013 8.66675 12.8434 8.66675 12.6666V8.66663H12.6667C12.8436 8.66663 13.0131 8.59639 13.1382 8.47136C13.2632 8.34634 13.3334 8.17677 13.3334 7.99996C13.3334 7.82315 13.2632 7.65358 13.1382 7.52855C13.0131 7.40353 12.8436 7.33329 12.6667 7.33329Z"
      fill="currentColor"
    />
  </svg>
)
