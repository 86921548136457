export const Approve = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2668 7.32508L8.69175 10.9084L7.31675 9.53341C7.24205 9.44618 7.15011 9.37533 7.04673 9.32531C6.94334 9.27529 6.83073 9.24718 6.71596 9.24275C6.6012 9.23832 6.48675 9.25766 6.37982 9.29955C6.27288 9.34145 6.17576 9.40499 6.09454 9.48621C6.01333 9.56742 5.94978 9.66454 5.90789 9.77148C5.86599 9.87842 5.84665 9.99286 5.85109 10.1076C5.85552 10.2224 5.88363 10.335 5.93365 10.4384C5.98367 10.5418 6.05452 10.6337 6.14175 10.7084L8.10009 12.6751C8.17795 12.7523 8.2703 12.8134 8.37183 12.8549C8.47336 12.8964 8.58208 12.9174 8.69175 12.9167C8.91037 12.9158 9.11987 12.829 9.27509 12.6751L13.4418 8.50842C13.5199 8.43095 13.5819 8.33878 13.6242 8.23723C13.6665 8.13568 13.6883 8.02676 13.6883 7.91675C13.6883 7.80674 13.6665 7.69782 13.6242 7.59627C13.5819 7.49472 13.5199 7.40255 13.4418 7.32508C13.2856 7.16987 13.0744 7.08275 12.8543 7.08275C12.6341 7.08275 12.4229 7.16987 12.2668 7.32508ZM10.0001 1.66675C8.35191 1.66675 6.74074 2.15549 5.37033 3.07117C3.99992 3.98685 2.93182 5.28834 2.30109 6.81105C1.67036 8.33377 1.50533 10.0093 1.82687 11.6258C2.14842 13.2423 2.94209 14.7272 4.10753 15.8926C5.27297 17.0581 6.75782 17.8517 8.37433 18.1733C9.99084 18.4948 11.6664 18.3298 13.1891 17.6991C14.7118 17.0683 16.0133 16.0002 16.929 14.6298C17.8447 13.2594 18.3334 11.6483 18.3334 10.0001C18.3334 8.90573 18.1179 7.8221 17.6991 6.81105C17.2803 5.80001 16.6665 4.88135 15.8926 4.10752C15.1188 3.3337 14.2002 2.71987 13.1891 2.30109C12.1781 1.8823 11.0944 1.66675 10.0001 1.66675ZM10.0001 16.6667C8.68154 16.6667 7.39261 16.2758 6.29628 15.5432C5.19996 14.8107 4.34547 13.7695 3.84089 12.5513C3.3363 11.3331 3.20428 9.99269 3.46152 8.69948C3.71875 7.40627 4.35369 6.21839 5.28604 5.28604C6.21839 4.35369 7.40628 3.71875 8.69948 3.46151C9.99269 3.20428 11.3331 3.3363 12.5513 3.84088C13.7695 4.34547 14.8107 5.19995 15.5432 6.29628C16.2758 7.39261 16.6668 8.68154 16.6668 10.0001C16.6668 11.7682 15.9644 13.4639 14.7141 14.7141C13.4639 15.9644 11.7682 16.6667 10.0001 16.6667Z"
      fill="currentColor"
    />
  </svg>
)
