import { FC, SVGProps } from 'react'

interface Props {
  isDisabled?: boolean
  onClick: () => void
  icon: FC<SVGProps<SVGSVGElement>>
  ariaLabel: string
}

export const IconButton = (props: Props) => {
  const { icon: Icon } = props
  return (
    <button
      aria-label={props.ariaLabel}
      className="flex h-10 w-10 items-center justify-center rounded border border-blue-500 p-0 text-blue-500 hover:border-blue-700 hover:bg-blue-700 hover:text-white disabled:border-gray-300 disabled:bg-gray-25 disabled:text-gray-300"
      disabled={props.isDisabled}
      onClick={props.onClick}
    >
      <Icon className="h-5 w-5" />
    </button>
  )
}
