import { SpotLayout } from '@spiaggeit/map-canvas'
import { Color, ElementType, SpotType } from '@spiaggeit/spit-core'

import { MapResponseElement } from '../models/map'

import { MAP_CELL_SIZE } from './constants'

export const getFormattedMapElement = (element: MapResponseElement) => {
  return {
    ...element,
    height: MAP_CELL_SIZE,
    iconColor: element.a ? Color.GREEN : Color.GREY,
    id: String(element.sid),
    layout: SpotLayout.BOOKING_NAME,
    name: element.n,
    rotation: 0,
    sectorId: element.s,
    serviceIcons: ['bikini.svg', 'policeman.svg'],
    staging: null,
    style: { iconColor: element.a ? Color.GREEN : Color.GREY },
    subType: element.t as SpotType,
    type: ElementType.SPOT as ElementType.SPOT,
    width: MAP_CELL_SIZE,
    x: element.pX * MAP_CELL_SIZE,
    y: element.pY * MAP_CELL_SIZE,
  }
}
