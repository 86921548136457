import { cn } from '@spiaggeit/spit-ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Minus } from '../../assets/icons/Minus'
import { Plus } from '../../assets/icons/Plus'
import { ItemQuantityControllerVariant } from '../../models/components'
import { priceFormatter } from '../../utils/price'
import { IconButton } from '../IconButton/IconButton'

interface Props {
  available: {
    global?: number
    group?: number
    ticket: number
  }
  hasLimit: boolean
  icon: string
  name: string
  minimumQuantity: number
  price?: number
  initialQuantity?: number
  showSoldOutLabel?: boolean
  onAdd: VoidFunction
  onRemove: VoidFunction
  variant?: ItemQuantityControllerVariant
}

export const ItemQuantityController = (props: Props) => {
  const { t } = useTranslation()
  const [quantity, setQuantity] = useState(props.initialQuantity || 0)

  const availableQuantity = props.hasLimit
    ? Math.min(
      props.available?.global || 0,
      props.available?.group || 0,
      props.available.ticket - quantity
    )
    : Infinity

  const isSoldOut = props.hasLimit && props?.available.ticket === 0

  useEffect(() => {
    if (props.initialQuantity) {
      setQuantity(props.initialQuantity)
    } else if (props.available.ticket < quantity) {
      setQuantity(props.available.ticket)
    }
  }, [props.initialQuantity, props.available.ticket])

  const handleRemove = () => {
    if (quantity > props.minimumQuantity) {
      setQuantity(quantity - 1)
      props.onRemove()
    }
  }

  const handleAdd = () => {
    if (availableQuantity > 0) {
      setQuantity(quantity + 1)
      props.onAdd()
    }
  }

  return (
    <div
      className={cn(
        'flex items-center justify-between gap-2 first:rounded-t last:rounded-b',
        {
          'cursor-not-allowed opacity-50': isSoldOut,
        }
      )}
    >
      {props.variant === ItemQuantityControllerVariant.DEFAULT
        ? (
            <div className="flex flex-auto items-center gap-3">
              <div className="flex-initial shrink-0">
                <img
                  alt="ticket"
                  className="h-8 w-8"
                  src={`/icons/${props.icon}`}
                />
              </div>

              <div className="flex-auto">
                <span className="line-clamp-2 text-ellipsis text-wrap text-left font-medium leading-5">
                  {props.name}
                </span>

                <p className="text-sm leading-5">
                  {props.price && props.price > 0 && (
                    <span>{priceFormatter.format(props.price)} </span>
                  )}

                  {isSoldOut && props.showSoldOutLabel
                    ? (
                        <span className="text-secondary">{t('listItem.soldOut')}</span>
                      )
                    : null}
                  {!isSoldOut && (props.minimumQuantity || props.hasLimit)
                    ? (
                        <span className="text-secondary">
                          {props.minimumQuantity > 0
                            ? (
                                <> {t('listItem.min', { value: props.minimumQuantity })}</>
                              )
                            : null}
                          {props.minimumQuantity > 0 && props.hasLimit ? ' · ' : null}
                          {props.hasLimit
                            ? (
                                <>{t('listItem.max', { value: props.available.ticket })}</>
                              )
                            : null}
                        </span>
                      )
                    : null}
                </p>
              </div>
            </div>
          )
        : null}

      <div className="flex-0 flex items-center">
        <IconButton
          ariaLabel={t('itemQuantityController.add')}
          icon={Minus}
          isDisabled={quantity === 0 || quantity <= props.minimumQuantity}
          onClick={handleRemove}
        />

        <div className="px-4 text-sm font-bold">{quantity}</div>

        <IconButton
          ariaLabel={t('itemQuantityController.remove')}
          icon={Plus}
          isDisabled={availableQuantity === 0}
          onClick={handleAdd}
        />
      </div>
    </div>
  )
}

ItemQuantityController.defaultProps = {
  variant: ItemQuantityControllerVariant.DEFAULT,
}
