import {
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit'
import { DateTime } from 'luxon'

import { httpClient } from '../lib/http/HttpClient'
import {
  changeQuantityActioType,
  QuoteSetupItems,
  SpotType,
} from '../models/cart'
import { MapElementWithSectorId, MapSetupItemKeyName } from '../models/map'
import { Service } from '../models/service'
import { fromISOtoTimestamp } from '../utils/dateUtils'
import { getHalfDayValueFromBookingPeriod } from '../utils/halfDay'

import { appSlice } from './appSlice'
import { BookingAvailability } from './bookingAvailabilitySlice'
import { RootState } from './configureStore'
import { createAppSlice } from './createAppSlice'
import { insertPeriodSlice } from './insertPeriodSlice'
import { licenseSlice } from './licenseSlice'
import { mapCurrentSectorSelector, mapSelectors, mapSlice } from './mapSlice'
import { servicesSlice } from './servicesSlice'

export interface QuoteOffer {
  id: number
  name: string
  value: number
}

export interface QuoteReservation {
  beds: number
  chairs: number
  deckChairs: number
  endDate: number
  halfDay: number
  maxiBeds: number
  notes: string
  seasonal: number
  sector: number
  services: Record<string, number>
  spotName: string
  spotType: MapElementWithSectorId['subType']
  startDate: number
}
export interface QuoteResponse {
  result: {
    totalPrice: number
    fees: number
    reservationPrices?: { [key: string]: number }
    offer?: number
  }
}

export interface CartSliceState {
  isQuotePending: boolean
  items: Record<
    number,
    {
      quantity: number
      id: number
    }
  >
  total: number
  quoteTotal: number
  fees: number
  quoteFees: number
  mapSpots: MapElementWithSectorId[]
  selectedBookingType?: BookingAvailability
  quoteReservations: QuoteReservation[]
  cartReservations: QuoteReservation[]
  reservationPrices?: QuoteResponse['result']['reservationPrices']
  quote: QuoteResponse['result']
  isQuoteLoading?: boolean
  selectedElementQuotableProducts: QuoteSetupItems
  selectedElementQuotedSeatsAmount: number
}

export const cartSliceInitialState: CartSliceState = {
  cartReservations: [],
  fees: 0,
  isQuotePending: false,
  items: {},
  mapSpots: [],
  quote: {
    fees: 0,
    totalPrice: 0,
  },
  quoteFees: 0,
  quoteReservations: [],
  quoteTotal: 0,
  selectedElementQuotableProducts: {
    b: 0,
    c: 0,
    d: 0,
    m: 0,
    services: {},
  },
  selectedElementQuotedSeatsAmount: 0,
  total: 0,
}

export const cartRemoveElementAndUpdateQuoteAction = createAsyncThunk<
  void,
  MapElementWithSectorId,
  { state: RootState }
>('cart/cartRemoveElementAndUpdateQuoteAction', async (element, thunkAPI) => {
  const { dispatch } = thunkAPI

  dispatch(cartSlice.actions.removeQuoteReservations(element))

  await dispatch(cartSlice.actions.quote()).unwrap()

  dispatch(
    cartSlice.actions.decrease({
      id: Number(element.id),
    })
  )

  dispatch(
    cartSlice.actions.setSelectedElementQuotableProducts(
      cartSliceInitialState.selectedElementQuotableProducts
    )
  )

  dispatch(mapSlice.actions.selectMapElement(null))
})

export const cartAddReservationToQuote = createAsyncThunk(
  'cart/cartAddReservationToQuote',
  async (_, thunkAPI) => {
    const { dispatch, getState } = thunkAPI
    const rootState = getState() as RootState
    const period = insertPeriodSlice.selectors.period(rootState)
    const bookingPeriod = insertPeriodSlice.selectors.bookingPeriod(rootState)
    const currentSector = mapCurrentSectorSelector(rootState)
    const selectedElement = mapSelectors.selectedElement(rootState)
    const selectedElementQuotableProducts =
      cartSlice.selectors.selectedElementQuotableProducts(rootState)
    const quoteReservations = cartSlice.selectors.quoteReservations(rootState)
    try {
      if (!period?.end || !period?.start || !currentSector || !selectedElement)
        return

      const { services, ...restOfQuoteSetupItems } =
        selectedElementQuotableProducts

      const reservation = {
        beds: restOfQuoteSetupItems.b,
        chairs: restOfQuoteSetupItems.c,
        deckChairs: restOfQuoteSetupItems.d,
        endDate: Number(fromISOtoTimestamp(period?.end)),
        halfDay: getHalfDayValueFromBookingPeriod(bookingPeriod),
        maxiBeds: restOfQuoteSetupItems.m,
        notes: '',
        seasonal: 0,
        sector: currentSector?.header.id,
        services: services,
        spotName: selectedElement?.name || '',
        spotType: selectedElement?.subType || SpotType.UMBRELLA,
        startDate: Number(fromISOtoTimestamp(period?.start)),
      }

      const reservationHasBeenAddedToQuote = !quoteReservations?.some(
        (res) => res.spotName === reservation.spotName
      )

      if (reservationHasBeenAddedToQuote) {
        dispatch(cartSlice.actions.addQuoteReservations(reservation))
      } else {
        dispatch(
          cartSlice.actions.editQuoteReservation({
            spotName: selectedElement.name,
            updates: reservation,
          })
        )
      }
    } catch (error) {
      return error
    }
  }
)

export const cartChangeQuotableSeatQuantity = createAsyncThunk<
  void,
  { dynamicKey: MapSetupItemKeyName; actionType: changeQuantityActioType },
  { state: RootState }
>('cart/cartChangeQuotableSeatQuantity', async (action, thunkAPI) => {
  const { dispatch, getState } = thunkAPI
  const rootState = getState()
  const selectedElementQuotableProducts =
    cartSlice.selectors.selectedElementQuotableProducts(rootState)

  const quotableItem =
    selectedElementQuotableProducts[
      action.dynamicKey as keyof Omit<QuoteSetupItems, 'services'>
    ]

  const quotableValue =
    action.actionType === changeQuantityActioType.ADD ? 1 : -1

  const updatedProducts = {
    ...selectedElementQuotableProducts,
    [action.dynamicKey]: quotableItem + quotableValue,
  }

  dispatch(
    cartSlice.actions.setSelectedElementQuotableProducts(updatedProducts)
  )
})

export const cartChangeQuotableServiceQuantity = createAsyncThunk<
  void,
  { dynamicKey: string; actionType: changeQuantityActioType },
  { state: RootState }
>('cart/cartChangeQuotableServiceQuantity', async (action, thunkAPI) => {
  const { dispatch, getState } = thunkAPI
  const rootState = getState()
  const selectedElementQuotableProducts =
    cartSlice.selectors.selectedElementQuotableProducts(rootState)

  const quotableItem =
    selectedElementQuotableProducts.services[
      action.dynamicKey as keyof Omit<QuoteSetupItems, 'services'>
    ] || 0 //please note: this 0 is necessary for cases when a service is not already quoted (e.g.: extra services which have not been added to quote yet)

  const quotableValue =
    action.actionType === changeQuantityActioType.ADD ? 1 : -1

  const updatedProducts = {
    ...selectedElementQuotableProducts,
    services: {
      ...selectedElementQuotableProducts?.services,
      [action.dynamicKey]: quotableItem + quotableValue,
    },
  }

  dispatch(
    cartSlice.actions.setSelectedElementQuotableProducts(updatedProducts)
  )
})

export const cartSlice = createAppSlice({
  extraReducers: (builder) => {
    builder.addCase(cartRemoveElementAndUpdateQuoteAction.pending, (state) => {
      state.isQuotePending = true
    })
    builder.addCase(
      cartRemoveElementAndUpdateQuoteAction.fulfilled,
      (state) => {
        state.isQuotePending = false
      }
    )
    builder.addCase(cartRemoveElementAndUpdateQuoteAction.rejected, (state) => {
      state.isQuotePending = false
    }),
    builder.addCase(cartAddReservationToQuote.pending, (state) => {
      state.isQuotePending = true
    })
    builder.addCase(cartAddReservationToQuote.fulfilled, (state) => {
      state.isQuotePending = false
    })
    builder.addCase(cartAddReservationToQuote.rejected, (state) => {
      state.isQuotePending = false
    })
    builder.addCase(cartChangeQuotableSeatQuantity.pending, (state) => {
      state.isQuotePending = true
    })
    builder.addCase(cartChangeQuotableSeatQuantity.fulfilled, (state) => {
      state.isQuotePending = false
    })
    builder.addCase(cartChangeQuotableSeatQuantity.rejected, (state) => {
      state.isQuotePending = false
    })
    builder.addCase(cartChangeQuotableServiceQuantity.pending, (state) => {
      state.isQuotePending = true
    })
    builder.addCase(cartChangeQuotableServiceQuantity.fulfilled, (state) => {
      state.isQuotePending = false
    })
    builder.addCase(cartChangeQuotableServiceQuantity.rejected, (state) => {
      state.isQuotePending = false
    })
  },
  initialState:
    cartSliceInitialState satisfies CartSliceState as CartSliceState,
  name: 'cart',
  reducers: (create) => ({
    add: create.reducer(
      (
        state,
        action: PayloadAction<{
          id: number
        }>
      ) => {
        const item = state.items[action.payload.id]
        if (item) {
          state.items[action.payload.id].quantity = state.items[
            action.payload.id
          ].quantity += 1
        } else {
          state.items[action.payload.id] = {
            id: action.payload.id,
            quantity: 1,
          }
        }
        state.total = state.quoteTotal
        state.fees = state.quoteFees
        if (state.quoteReservations) {
          state.cartReservations = state.quoteReservations
        }
      }
    ),
    addQuoteReservations: create.reducer(
      (state, action: PayloadAction<QuoteReservation>) => {
        state.quoteReservations.push(action.payload)
      }
    ),
    decrease: create.reducer((state, action: PayloadAction<{ id: number }>) => {
      const item = state.items[action.payload.id]
      if (item && item.quantity > 1) {
        state.items[action.payload.id].quantity = Math.max(0, item.quantity - 1)
      } else {
        delete state.items[action.payload.id]
      }
      state.total = state.quoteTotal
      state.fees = state.quoteFees
      if (state.quoteReservations) {
        state.cartReservations = state.quoteReservations
      }
    }),
    editQuoteReservation: create.reducer(
      (
        state,
        action: PayloadAction<{
          spotName: string
          updates: Partial<QuoteReservation>
        }>
      ) => {
        if (!state.quoteReservations) return

        const { spotName, updates } = action.payload
        const reservationIndex = state.quoteReservations.findIndex(
          (reservation) => reservation.spotName === spotName
        )
        const updatedReservation = {
          ...state.quoteReservations[reservationIndex],
          ...updates,
        }
        state.quoteReservations[reservationIndex] = updatedReservation
      }
    ),
    quote: create.asyncThunk<
      {
        fees: number
        totalPrice: number
        reservationPrices?: QuoteResponse['result']['reservationPrices']
        offer?: Record<string, QuoteOffer> | []
        isQuoteForTickets?: boolean
      },
      { isQuoteForTickets?: boolean } | undefined,
      { rejectValue: string }
    >(
      async (
        { isQuoteForTickets } = {},
        thunkApi
      ): Promise<{
        fees: number
        totalPrice: number
        reservationPrices?: QuoteResponse['result']['reservationPrices']
        isQuoteForTickets?: boolean
      }> => {
        const rootState = thunkApi.getState() as RootState
        const license = licenseSlice.selectors.license(rootState)
        const period = insertPeriodSlice.selectors.period(rootState)
        const channel = appSlice.selectors.channel(rootState)
        const country = appSlice.selectors.country(rootState)
        const quoteReservations =
          cartSlice.selectors.quoteReservations(rootState)

        if (!license) {
          throw thunkApi.rejectWithValue('License not found')
        }

        if (!period) {
          throw thunkApi.rejectWithValue('Period not found')
        }
        const isQuoteReservationNotEmpty =
          quoteReservations && quoteReservations.length >= 1
        const res = await httpClient.fetch<QuoteResponse>(
          `beaches/${license.license}/carts/quote`,
          country,
          {
            body: JSON.stringify({
              channel,
              reservations: (isQuoteReservationNotEmpty
                ? quoteReservations
                : [
                    {
                      beds: 0,
                      chairs: 0,
                      deckChairs: 0,
                      endDate: DateTime.fromFormat(period.end, 'yyyy-LL-dd', {
                        zone: 'utc',
                      }).toSeconds(),
                      halfDay: 0,
                      maxiBeds: 0,
                      notes: '',
                      seasonal: 0,
                      sector: 0,
                      services: Object.values(rootState.cart.items).reduce(
                        (acc, item) => ({
                          ...acc,
                          [`id${item.id}`]: item.quantity,
                        }),
                        {}
                      ),
                      spotName: null,
                      spotType: null,
                      startDate: DateTime.fromFormat(
                        period.start,
                        'yyyy-LL-dd',
                        {
                          zone: 'utc',
                        }
                      ).toSeconds(),
                    },
                  ]
              ).reduce((acc, reservation, index) => {
                const dynamicKey =
                  reservation.spotType && reservation.spotName
                    ? `_${reservation.spotType}_${reservation.spotName}`
                    : `${index}`
                return {
                  ...acc,
                  [dynamicKey]: reservation,
                }
              }, {}),
            }),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
          }
        )

        if (res.status === 'error') {
          throw thunkApi.rejectWithValue(res.error)
        }

        return { ...res.data.result, isQuoteForTickets }
      },
      {
        fulfilled: (state, action) => {
          const offer =
            action.payload?.offer &&
            Object.values(action.payload?.offer)?.reduce(
              (acc, cur) => acc + cur?.value,
              0
            )
          state.quoteTotal = action.payload.totalPrice
          state.quoteFees = action.payload.fees
          state.reservationPrices = action.payload?.reservationPrices
          state.quote = {
            ...action.payload,
            offer,
          }
          state.isQuotePending = false
          if (action.payload.isQuoteForTickets) {
            state.fees = action.payload.fees
            state.total = action.payload.totalPrice
          }
        },
        pending: (state) => {
          state.isQuotePending = true
        },
      }
    ),
    removeQuoteReservations: create.reducer(
      (state, action: PayloadAction<MapElementWithSectorId | undefined>) => {
        const element = action.payload
        const quoteReservations = state.quoteReservations

        if (!quoteReservations || !element) {
          return
        }

        const reservationToRemove = quoteReservations.find(
          (item) => item.spotName === element?.name
        )

        if (!reservationToRemove) {
          return
        }

        state.quoteReservations = state.quoteReservations?.filter(
          (item) => item.spotName !== reservationToRemove.spotName
        )

        state.quoteTotal = 0
        state.quoteFees = 0
      }
    ),
    reset: create.reducer(() => {
      return cartSliceInitialState
    }),
    selectBookingType: create.reducer(
      (
        state,
        action: PayloadAction<{ selectedBookingType: BookingAvailability }>
      ) => {
        state.selectedBookingType = action.payload.selectedBookingType
      }
    ),
    set: create.reducer(
      (state, action: PayloadAction<{ id: number; quantity: number }>) => {
        state.items[action.payload.id] = action.payload
      }
    ),
    setCartReservations: create.reducer(
      (state, action: PayloadAction<QuoteReservation[]>) => {
        state.cartReservations = action.payload
      }
    ),
    setSelectedElementQuotableProducts: create.reducer(
      (state, action: PayloadAction<QuoteSetupItems>) => {
        state.selectedElementQuotableProducts = action.payload
      }
    ),
  }),
  selectors: {
    cartReservations: (state) => state.cartReservations,
    fees: (state) => {
      const totalQuantity = cartSlice.selectors.totalQuantity({ cart: state })
      if (totalQuantity === 0) return 0
      return state.fees
    },
    isQuotePending: (state) => state.isQuotePending,
    itemById: (state, id: number) => state.items[id],
    items: (state) => state.items,
    quote: (state) => state.quote,
    quoteFees: (state) => state.quoteFees,
    quoteReservations: (state) => state.quoteReservations,
    quoteTotal: (state) => state.quoteTotal,
    reservationPrices: (state) => state.reservationPrices,
    selectedBookingType: (state) => state.selectedBookingType,
    selectedElementQuotableProducts: (state) =>
      state.selectedElementQuotableProducts,
    selectedElementQuotedSeatsAmount: (state) => {
      const { b, c, d, m } = state.selectedElementQuotableProducts
      return [b, c, d, m].reduce((acc, item) => {
        acc = acc + item
        return acc
      })
    },
    total: (state) => state.total,
    totalQuantity: (state) =>
      Object.values(state.items).reduce((acc, item) => acc + item.quantity, 0),
  },
})

export const cartMapSpotsSelector = createSelector(
  [mapSelectors.mapData, cartSlice.selectors.items],
  (mapData, items) => {
    return mapData?.elements
      ?.filter((element) => Object.keys(items).includes(element.id))
      .filter(Boolean)
  }
)

export const cartPayloadSelector = createSelector(
  [
    appSlice.selectors.channel,
    licenseSlice.selectors.license,
    insertPeriodSlice.selectors.period,
    cartSlice.selectors.items,
  ],
  (channel, license, period, items) => {
    if (!channel || !license || !period) return null
    return {
      beachLicense: license.license,
      channel,
      registrations: {
        _ticket_00: {
          beds: 0,
          chairs: 0,
          deckchairs: 0,
          end: DateTime.fromFormat(period.end, 'yyyy-LL-dd', {
            zone: 'utc',
          }).toSeconds(),
          halfDay: 0,
          maxibeds: 0,
          note: '',
          seasonal: 0,
          sector: 0,
          services: Object.values(items).reduce(
            (acc, item) => ({
              ...acc,
              [`id${item.id}`]: item.quantity,
            }),
            {}
          ),
          spotName: '',
          spotType: 'ticket',
          start: DateTime.fromFormat(period.start, 'yyyy-LL-dd', {
            zone: 'utc',
          }).toSeconds(),
        },
      },
    }
  }
)

type ServiceWithQuantity = Service & { quantity: number }
export const cartItemsWithDetailsSelector = createSelector(
  [cartSlice.selectors.items, servicesSlice.selectors.self],
  (cartItems, services) => {
    if (services.status !== 'success') return []

    return Object.keys(cartItems)
      .map((id): ServiceWithQuantity | null => {
        const service = services.data.serviceGroups
          .flatMap((group) => group.services)
          .find((service) => service.serviceId === Number(id))

        if (!service) return null

        return {
          ...service,
          quantity: cartItems[Number(id)].quantity,
        }
      })
      .filter((item) => item && item.quantity > 0) as ServiceWithQuantity[]
  }
)
