export enum ServiceLimitType {
  FLAT = 'flat',
  NUMBER_OF_PIECES = 'number-of-pieces',
  NUMBER_OF_DAYS = 'number-of-days',
}

export interface ServicesInfoResponse {
  result: {
    limits: {
      availableQuantity: number
      hasLimit: boolean
      serviceGroupLimits: {
        serviceGroupId: number
        hasLimit: boolean
        name: string
        availableQuantity: number
        serviceLimits: {
          serviceId: number
          hasLimit: boolean
          name: string
          price: number
          icon: string
          minimumQuantity: number
          maximumQuantity: number
          availableQuantity: number
          minimumLimitType?: ServiceLimitType
          maximumLimitType?: ServiceLimitType
        }[]
      }[]
      extraServiceLimits: Service[]
    }
  }
}

export interface Service {
  serviceId: number
  hasLimit: boolean
  name: string
  price: number
  icon: string
  minimumQuantity: number
  maximumQuantity: number
  availableQuantity: number
  minimumLimitType?: ServiceLimitType
  maximumLimitType?: ServiceLimitType
}

export interface ServiceGroup {
  serviceGroupId: number
  hasLimit: boolean
  name: string
  availableQuantity: number
  services: Service[]
}
