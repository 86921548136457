import { init, MapCanvasApi } from '@spiaggeit/map-canvas'
import { Color } from '@spiaggeit/spit-core'
import { Button, Dialog } from '@spiaggeit/spit-ui'
import { useEffect, useMemo, useRef, useState } from 'react'
import FocusLock from 'react-focus-lock'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { INSERT_PERIOD_PATH } from '../../app/router/paths'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { cartSlice } from '../../store/cartSlice'
import { insertPeriodSlice } from '../../store/insertPeriodSlice'
import { licenseSlice } from '../../store/licenseSlice'
import {
  loadMapData,
  loadSectors,
  mapSelectors,
  mapSlice,
} from '../../store/mapSlice'
import { toastSlice } from '../../store/toastSlice'
import { MAP_CELL_SIZE } from '../../utils/constants'

import { CartBottomDrawer } from './CartBottomDrawer'
import { FirstVisitDialog } from './FirstVisitDialog'
import { LegendDialog } from './LegendDialog'
import { ElementDetail } from './SelectedElementDrawer'

export const MapRoute = () => {
  const mapCanvasApiRef = useRef<null | MapCanvasApi>(null)
  const license = useAppSelector(licenseSlice.selectors.license)
  const dispatch = useAppDispatch()
  const period = useAppSelector(insertPeriodSlice.selectors.period)
  const navigate = useNavigate()

  useEffect(() => {
    if (!period || !period.end || !period.start) {
      navigate(generatePath(INSERT_PERIOD_PATH, { license: license?.license }))
    }
  }, [period, INSERT_PERIOD_PATH, license])

  const { t } = useTranslation()

  const mapData = useAppSelector(mapSelectors.mapData)
  const isLoading = useAppSelector(mapSelectors.isLoading)
  const selectedElement = useAppSelector(mapSelectors.selectedElement)

  useEffect(() => {
    dispatch(loadMapData())
    dispatch(loadSectors())
  }, [])

  const cartItemsIds = Object.keys(useAppSelector(cartSlice.selectors.items))
  useEffect(() => {
    mapData?.elements?.map((element) => {
      if (cartItemsIds.includes(element.id)) {
        mapCanvasApiRef.current?.highlightElement(element.id, Color.BLUE)
      } else {
        mapCanvasApiRef.current?.clearHighlight([element.id])
      }
    })
  }, [cartItemsIds, mapData])

  const handleMapElementClick = (elementId: string) => {
    if (!mapData?.elements) return

    const element = mapData.elements.find((element) => element.id == elementId)

    if (!element || !element.style) return
    const isAvailable = element.style.iconColor === Color.GREEN

    if (isAvailable) {
      dispatch(mapSlice.actions.selectMapElement(element))
    } else {
      dispatch(
        toastSlice.actions.show({
          ctaCopy: t('common.messageUnderstood'),
          message: t('map.errors.unavailableMapSpot'),
        })
      )
    }
  }

  useEffect(() => {
    if (
      !mapData ||
      !mapData?.elements ||
      !mapData?.background ||
      !mapData?.size
    )
      return
    init({
      events: {
        onMapElementClick(id) {
          handleMapElementClick(id)
        },
      },
      opts: {
        assetsBaseUrl: 'https://assets.staging.spiagge.it/booking/map',
        background: mapData.background,
        containerId: 'canvas',
        elements: [...(mapData?.elements ?? [])],
        size: {
          height: mapData?.size.height * MAP_CELL_SIZE,
          width: mapData?.size.width * MAP_CELL_SIZE,
        },
      },
    })
      .then((mapCanvasApi) => {
        mapCanvasApiRef.current = mapCanvasApi
      })
      // TODO: handle error
      // eslint-disable-next-line no-console
      .catch(console.error)

    return () => {
      mapCanvasApiRef.current?.destroy()
    }
  }, [mapData])

  const availableSpots = useMemo(() => {
    return mapData?.elements?.filter((e) => e.style?.iconColor === Color.GREEN)
  }, [mapData])

  const [isUnavailableSpotsDialogOpen, setIsUnavailableSpotsDialogOpen] =
    useState(false)
  const [isLastSpotDialogOpen, setIsLastSpotDialogOpen] = useState(false)

  useEffect(() => {
    if (isLoading) return
    if (availableSpots && availableSpots?.length < 1) {
      setIsUnavailableSpotsDialogOpen(true)
    } else if (availableSpots && availableSpots.length === 1) {
      setIsLastSpotDialogOpen(true)
    }
  }, [
    isLoading,
    availableSpots,
    setIsUnavailableSpotsDialogOpen,
    setIsLastSpotDialogOpen,
  ])

  const onSelectLastAvailableSpot = () => {
    if (!availableSpots) return
    dispatch(mapSlice.actions.selectMapElement(availableSpots[0]))
    setIsLastSpotDialogOpen(false)
  }

  if (isLoading) return <span>Loading...</span> // TODO: add loading spinner
  return (
    <main className="relative flex flex-1">
      <div className="max-w-full flex-1 overflow-scroll" id="canvas"></div>
      <span className="fixed left-4 top-32 z-20 lg:hidden">
        <LegendDialog />
      </span>

      {availableSpots && availableSpots.length >= 1 && <FirstVisitDialog />}
      <CartBottomDrawer
        zoomIn={() => mapCanvasApiRef.current?.zoomIn()}
        zoomOut={() => mapCanvasApiRef.current?.zoomOut()}
      />
      {selectedElement && <ElementDetail />}
      <Dialog
        isOpen={isUnavailableSpotsDialogOpen}
        scrollContent
        setIsOpen={setIsUnavailableSpotsDialogOpen}
        size="sm"
        title={t('map.unavailableSpotsDialog.title')}
      >
        <div className="border-t border-t-gray-300 p-4">
          <p className="text-base">{t('map.unavailableSpotsDialog.content')}</p>
          <Button className="mb-2 mt-8" fullWidth>
            {t('map.unavailableSpotsDialog.goBack')} {/* TODO: add redirect */}
          </Button>
          <Button
            fullWidth
            onClick={() => {
              setIsUnavailableSpotsDialogOpen(false)
              navigate(
                `${generatePath(INSERT_PERIOD_PATH, {
                  license: license?.license,
                })}`
              )
            }}
            variant="outline"
          >
            {t('map.unavailableSpotsDialog.changeDates')}
          </Button>
        </div>
      </Dialog>
      <FocusLock>
        <Dialog
          isOpen={isLastSpotDialogOpen}
          scrollContent
          setIsOpen={setIsLastSpotDialogOpen}
          size="sm"
          title={t('map.lastAvailableSpotDialog.title')}
        >
          <div className="border-t border-t-gray-300 p-4">
            <p className="text-base">
              {t('map.lastAvailableSpotDialog.content')}
            </p>
            <Button
              className="mb-2 mt-8"
              fullWidth
              onClick={onSelectLastAvailableSpot}
            >
              {t('map.lastAvailableSpotDialog.seeAvailableElement')}
            </Button>
            <Button
              fullWidth
              onClick={() => {
                setIsLastSpotDialogOpen(false)
                navigate(INSERT_PERIOD_PATH)
              }}
              variant="outline"
            >
              {t('map.lastAvailableSpotDialog.changeDates')}
            </Button>
          </div>
        </Dialog>
      </FocusLock>
    </main>
  )
}
