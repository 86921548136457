import { SpotType } from '@spiaggeit/spit-core'

export const mockedSetupItems = [
  {
    available: { global: 60, group: 3, ticket: 6 },
    hasLimit: true,
    icon: 'aperol.svg',
    id: 111,
    minimumQuantity: 0,
    name: 'Aperol spritz',
    onAdd: () => {
      return
    },
    onRemove: () => {
      return
    },
    price: 6,
  },
  {
    available: { global: 60, group: 3, ticket: 6 },
    hasLimit: true,
    icon: 'champagne.svg',
    id: 222,
    minimumQuantity: 0,
    name: 'Champagne',
    onAdd: () => {
      return
    },
    onRemove: () => {
      return
    },
    price: 34,
  },
  {
    available: { global: 60, group: 6, ticket: 6 },
    hasLimit: true,
    icon: 'hamburger.svg',
    id: 333,
    minimumQuantity: 0,
    name: 'Hamburger Menu',
    onAdd: () => {
      return
    },
    onRemove: () => {
      return
    },
    price: 12,
  },
]

export const mockedSectorCardImage = {
  alt: 'a random text',
  url: 'https://img.spiagge.it/images/site/msm/5f058796cb62a-1594197910.webp',
}

export const mockedSectorCards = [
  {
    img: mockedSectorCardImage,
    sectorName: '1° fila',
    spotName: 'Spiaggia',
    spotType: SpotType.UMBRELLA,
  },
  {
    sectorName: '1° fila',
    spotName: 'Spiaggia',
    spotType: SpotType.GAZEBO,
  },
  {
    img: mockedSectorCardImage,
    sectorName: '2° fila con nome più lungo del solito',
    spotName: 'Piscina',
    spotType: SpotType.BED,
  },
  {
    sectorName: '3° fila',
    spotName: 'Spiaggia',
    spotType: SpotType.BEDS,
  },
  {
    img: mockedSectorCardImage,
    sectorName: '4° fila',
    spotName: 'Spiaggia',
    spotType: SpotType.BOAT,
  },
  {
    img: mockedSectorCardImage,
    sectorName: '5° fila',
    spotName: 'Spiaggia',
    spotType: SpotType.PARKING,
  },
  {
    sectorName: 'Molo',
    spotName: 'Spiaggia',
    spotType: SpotType.PLAYFIELD,
  },
  {
    mockedSectorCardImage,
    sectorName: '6° fila',
    spotName: 'Piscina dal nome molto molto lungo',
    spotType: SpotType.CABIN,
  },
]
