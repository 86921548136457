import { mockedSectorCards } from '../../utils/mockedData'

import { SectorCard } from './SectorCard'

export const SectorsRoute = () => {
  return (
    <div className="p-4">
      <div className="flex flex-col items-stretch gap-4 lg:grid lg:grid-cols-3 lg:flex-row lg:items-start">
        {/* please note: once sectors logic is implement, this data will be dynamic  */}
        {mockedSectorCards.map((card, index) => (
          <SectorCard key={index} {...card} />
        ))}
      </div>
    </div>
  )
}
