import { cn } from '@spiaggeit/spit-ui'

export type ToggleGroupProps<T> = {
  className?: string
  options: {
    label: string
    value: T
  }[]
  value: T

  onValueChange: (value: T) => void
}

export function ToggleGroup<T>(props: ToggleGroupProps<T>) {
  return (
    <div className={cn('flex', props.className)}>
      {props.options.map((option, index) => {
        const isActive = option.value === props.value

        return (
          <button
            className={cn(
              'min-w-0 flex-1 overflow-hidden whitespace-nowrap border-y border-blue-500 p-2 transition-all',
              {
                // active style
                'bg-blue-500 text-white': isActive,
                //  inactive style
                'bg-white text-secondary hover:bg-blue-100 hover:text-blue-500':
                  !isActive,
                // first element style
                'rounded-l border-l': index === 0,
                // last element style
                'rounded-r border-r': index === props.options.length - 1,
              }
            )}
            key={index}
            onClick={() => {
              if (props.value !== option.value) {
                props.onValueChange(option.value)
              }
            }}
            type="button"
          >
            <span className="overflow-hidden text-ellipsis">
              {option.label}
            </span>
          </button>
        )
      })}
    </div>
  )
}
