import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../../hooks/store'
import { QuoteSetupItems } from '../../../models/cart'
import { cartSlice } from '../../../store/cartSlice'
import {
  mapSelectedElementSetupDataSelector,
  mapSelectors,
} from '../../../store/mapSlice'

import { SetupSeatListItem } from './SetupSeatListItem'
import { SetupServiceList } from './SetupServiceList'

export const SetupProductsList = () => {
  const { t } = useTranslation()
  const selectedElement = useAppSelector(mapSelectors.selectedElement)
  const selectedElementQuotedSeatsAmount = useAppSelector(
    cartSlice.selectors.selectedElementQuotedSeatsAmount
  )
  const selectedElementQuotableProducts = useAppSelector(
    cartSlice.selectors.selectedElementQuotableProducts
  )
  const cartItems = useAppSelector(cartSlice.selectors.items)

  const setupData = useAppSelector(mapSelectedElementSetupDataSelector)

  const selectedElementSetupItems = setupData?.selectedElementSetupItems
  const selectedElementSetupLimit = setupData?.selectedElementSetupLimit

  if (!selectedElement || !selectedElementSetupItems) return null

  const isMinimumSeatsAmountSelected = selectedElementSetupLimit?.min
    ? selectedElementQuotedSeatsAmount >= selectedElementSetupLimit?.min
    : selectedElementQuotedSeatsAmount > 0

  const isSelectedElementAddedToCart = Object.keys(cartItems).includes(
    `${selectedElement.id}`
  )

  const filteredSetupItems = selectedElementSetupItems
    ?.filter((item) => item.min > 0 || item.d > 0)
    .map((item) => {
      return {
        ...item,
        d: isSelectedElementAddedToCart
          ? selectedElementQuotableProducts[
              item.keyName as keyof Omit<QuoteSetupItems, 'services'>
          ]
          : item.d,
      }
    })

  return (
    <div className="flex flex-col gap-2 p-3">
      <div className="border-b border-solid pb-3">
        <h3 className="font-bold text-primary">
          {t('map.elementDetail.setup')}
        </h3>
        <div className="flex flex-col gap-3 border-b border-solid py-3">
          {filteredSetupItems.map((item) => {
            const remainingGlobal = Math.max(
              (selectedElementSetupLimit?.max || 0) -
                selectedElementQuotedSeatsAmount,
              0
            )

            return (
              <SetupSeatListItem
                item={item}
                key={item.keyName}
                remainingGlobal={remainingGlobal}
              />
            )
          })}
        </div>

        <div className="mt-3">
          <div className="flex justify-between">
            <h3 className="text-md font-bold text-primary">
              {t('map.elementDetail.totalSeats')}
            </h3>
            <div className="ml-auto flex w-32 flex-col gap-3 text-center">
              <span className="text-sm font-bold text-primary">
                {selectedElementQuotedSeatsAmount}
              </span>
              {!isMinimumSeatsAmountSelected && (
                <span className="text-xs text-red-500">
                  {t('map.elementDetail.minimumSeatsRequired', {
                    count:
                      (selectedElementSetupLimit?.min || 0) -
                      selectedElementQuotedSeatsAmount,
                  })}
                </span>
              )}
            </div>
          </div>
          <span className="text-md text-secondary">
            {t('listItem.min', { value: selectedElementSetupLimit?.min })}{' '}
            {t('listItem.max', { value: selectedElementSetupLimit?.max })}
          </span>
        </div>
      </div>

      <SetupServiceList />
    </div>
  )
}
