import { SpotType } from '@spiaggeit/spit-core'
import { FC, SVGProps } from 'react'

import { BasketBall } from '../assets/icons/BasketBall'
import { Boat } from '../assets/icons/Boat'
import { Cabin } from '../assets/icons/Cabin'
import { Gazebo } from '../assets/icons/Gazebo'
import { Parking } from '../assets/icons/Parking'
import { Sunbed } from '../assets/icons/Sunbed'
import { Sunbeds } from '../assets/icons/Sunbeds'
import { SunUnbrella } from '../assets/icons/SunUnbrella'

export const MAP_CELL_SIZE = 39

export const mappedSpotTypes: Record<
  string,
  { icon: FC<SVGProps<SVGSVGElement>>; label: string }
> = {
  [SpotType.BED]: {
    icon: Sunbed,
    label: 'spotTypes.bed',
  },
  [SpotType.BEDS]: {
    icon: Sunbeds,
    label: 'spotTypes.beds',
  },
  [SpotType.BOAT]: {
    icon: Boat,
    label: 'spotTypes.boat',
  },
  [SpotType.CABIN]: {
    icon: Cabin,
    label: 'spotTypes.cabins',
  },
  [SpotType.GAZEBO]: {
    icon: Gazebo,
    label: 'spotTypes.gazebo',
  },
  [SpotType.PARKING]: {
    icon: Parking,
    label: 'spotTypes.parking',
  },
  [SpotType.PLAYFIELD]: {
    icon: BasketBall,
    label: 'spotTypes.playfield',
  },
  [SpotType.UMBRELLA]: {
    icon: SunUnbrella,
    label: 'spotTypes.umbrella',
  },
}
export const mapSetupElements = {
  b: {
    icon: 'bed.svg',
    label: 'map.elementDetail.setupItems.bed',
    quoteReservationKey: 'beds',
  },
  c: {
    icon: 'chair.svg',
    label: 'map.elementDetail.setupItems.chair',
    quoteReservationKey: 'chairs',
  },
  d: {
    icon: 'deck-chair.svg',
    label: 'map.elementDetail.setupItems.deckChair',
    quoteReservationKey: 'deckChairs',
  },
  i: { icon: '', label: 's', quoteReservationKey: '' },
  m: {
    icon: 'maxi-bed.svg',
    label: 'map.elementDetail.setupItems.maxiBed',
    quoteReservationKey: 'maxiBeds',
  },
}

export const cartSetupElements = Object.values(mapSetupElements).filter(
  (setupElement) => setupElement.label !== 's'
)

export const FALLBACK_LICENSE_BOOKING_USER_UMBRELLAS_LIMIT = 9999
