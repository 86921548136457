import { SpotType } from '@spiaggeit/spit-core'
import { useTranslation } from 'react-i18next'

import { ItemQuantityController } from '../../components/ItemQuantityController/ItemQuantityController'
import { ItemQuantityControllerVariant } from '../../models/components'
import { mappedSpotTypes } from '../../utils/constants'

interface Props {
  img?: {
    url: string
    alt: string
  }
  spotName: string
  sectorName: string
  spotType: SpotType
}

export const SectorCard = (props: Props) => {
  const { t } = useTranslation()
  const { icon: Icon, label } = mappedSpotTypes[props.spotType]
  return (
    <div className="overflow-hidden rounded border border-solid shadow">
      {props.img
        ? (
            <img
              alt={props.img?.alt}
              className="h-[136px] w-full object-cover"
              src={props.img?.url}
            />
          )
        : null}
      <div className="flex gap-4 bg-white p-4">
        <div className="flex grow items-center gap-1">
          <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-circle bg-yellow-300">
            <Icon className="h-6 w-6 text-white" />
          </div>
          <div>
            <h3 className="line-clamp-1 overflow-hidden text-ellipsis text-base font-bold">
              {props.spotName} · {props.sectorName}
            </h3>
            <span className="text-base">{t(label)}</span>
          </div>
        </div>
        {/* please note: once sectors logic is implement, this data will be dynamic  */}
        <ItemQuantityController
          available={{ global: 1, group: 1, ticket: 1 }}
          hasLimit
          icon=""
          minimumQuantity={0}
          name=""
          onAdd={() => {
            return
          }}
          onRemove={() => {
            return
          }}
          price={0}
          variant={ItemQuantityControllerVariant.COMPACT}
        />
      </div>
    </div>
  )
}
