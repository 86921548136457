import { Button, Dialog } from '@spiaggeit/spit-ui'
import { useState } from 'react'
import FocusLock from 'react-focus-lock'
import { useTranslation } from 'react-i18next'

import { DiagonalResize } from '../../assets/icons/DiagonalResize'
import { Move } from '../../assets/icons/Move'

import { LegendContent } from './LegendContent'
export const FirstVisitDialog = () => {
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState(true)

  return (
    <FocusLock>
      <Dialog isOpen={isDialogOpen} scrollContent size="sm">
        <div className="flex grow flex-col space-y-3 p-4">
          <h3 className="text-gray-800 text-lg font-bold">
            {t('map.dialog.title')}
          </h3>
          <div className="hidden lg:block lg:grow">
            <LegendContent />
          </div>

          <div className="flex flex-col lg:hidden">
            <div className="flex items-center gap-2">
              <span className="shrink-0">
                <DiagonalResize />
              </span>
              <span className="text-gray-600 text-sm font-medium">
                {t('map.dialog.resizeInstructions')}
              </span>
            </div>
            <div className="mb-3 flex items-center gap-2">
              <span className="shrink-0">
                <Move />
              </span>
              <span className="text-gray-600 text-sm font-medium">
                {t('map.dialog.moveInstructions')}
              </span>
            </div>
          </div>

          <Button fullWidth onClick={() => setIsDialogOpen(false)}>
            {t('map.dialog.closeButton')}
          </Button>
        </div>
      </Dialog>
    </FocusLock>
  )
}
