import { MapElement as CanvasApiMapElement } from '@spiaggeit/map-canvas'

export interface MapResponseElement {
  sid: number
  t: string
  pX: number
  pY: number
  n: string
  pl: number
  plo: number
  bgc: string
  o: boolean
  s: number
  a: boolean
}

interface MapResponseBackground {
  posX: number
  posY: number
  height: number
  width: number
  url: string
}

export interface MapResponse {
  result: {
    background: MapResponseBackground
    elements: MapResponseElement[]
    height: number
    width: number
  }
}

export enum MapSetupItemKeyName {
  BED = 'b',
  CHAIR = 'c',
  COMBINED_LIMIT = 'i',
  DECK_CHAIR = 'd',
  MAXI_BED = 'm',
}

export type SectorHeader = {
  name: string
  description: string
  image: string | null
  licenseCode: string
  enabled: boolean
  id: number
  oldId: number
}

export type SectorDetail = {
  sectorId: number
  type: SectorDetailType
  keyName: string
  keyType: APISectorDetailKeyType
  keyValue: number
  id: number
}

export type Sector = {
  header: SectorHeader
  details: SectorDetail[]
  canBeDeleted: boolean
}

export enum SectorDetailType {
  OFFLINE = 'offline',
  ONLINE = 'online',
}

export enum APISectorDetailKeyType {
  DEFAULT = 'd',
  MAX = 'max',
  MIN = 'min',
}

export interface MapSetupItem {
  keyName: MapSetupItemKeyName
  label: string
  [APISectorDetailKeyType.MIN]: number
  [APISectorDetailKeyType.MAX]: number
  [APISectorDetailKeyType.DEFAULT]: number
  icon: string
  sectorId: number
}

export type MapElementWithSectorId = CanvasApiMapElement & {
  sectorId?: number
}
