import { cn, Dialog } from '@spiaggeit/spit-ui'
import FocusLock from 'react-focus-lock'
import { useTranslation } from 'react-i18next'

import { Approve } from '../../../assets/icons/Approve'
import { TriangleExclamationMark } from '../../../assets/icons/TriangleExclamationMark'
import { useAppSelector } from '../../../hooks/store'
import { licenseSlice } from '../../../store/licenseSlice'

export const RefundPolicy = () => {
  const { t } = useTranslation()
  const license = useAppSelector(licenseSlice.selectors.license)

  if (!license) return null

  const isBookingRefundable =
    license.bookingRefundPolicy && license.bookingRefundPolicy >= 1

  const paragraphs = [
    {
      description: t('bookingConditions.advancePaymentDescription'),
      title: t('bookingConditions.advancePaymentTitle'),
    },
    {
      description: t('bookingConditions.refundableByVoucherDescription', {
        days: license.bookingRefundPolicy,
        licenseName: license.name,
      }),
      title: t('bookingConditions.refundableByVoucherTitle'),
    },
    {
      description: t('bookingConditions.refundTimetableDescription', {
        days: license.bookingRefundPolicy,
      }),
      title: t('bookingConditions.refundTimetableTitle'),
    },
  ]

  return (
    <div
      className={cn('rounded-sm border p-1', {
        'border-green-100 bg-green-25': isBookingRefundable,
        'border-red-100 bg-red-25': !isBookingRefundable,
      })}
    >
      <div className="flex items-start gap-1">
        {isBookingRefundable
          ? (
              <Approve className="h-5 w-5 shrink-0 text-green-500" />
            )
          : (
              <TriangleExclamationMark className="shrink-0 text-red-500" />
            )}
        <span className="text-sm">
          {isBookingRefundable
            ? t('map.elementDetail.refundPolicy.positive', {
              count: license.bookingRefundPolicy,
              licenseName: license.name,
            })
            : t('map.elementDetail.refundPolicy.negative')}
        </span>
      </div>
      <FocusLock>
        <Dialog
          scrollContent
          size="sm"
          title={t('bookingConditions.title')}
          trigger={
            <button className="ml-1 font-medium">
              + {t('common.moreInfo')}
            </button>
          }
        >
          <div className="p-4">
            {paragraphs.map((paragraph, index) => (
              <div
                className={cn('pb-4', {
                  'border-t border-t-gray-300 pt-4': index > 0,
                })}
                key={paragraph.title}
              >
                <h3 className="font-bold text-primary">{paragraph.title}</h3>
                <p className="text-secondary">{paragraph.description}</p>
              </div>
            ))}
          </div>
        </Dialog>
      </FocusLock>
    </div>
  )
}
