import { Button, Toast as ToastSpitUi } from '@spiaggeit/spit-ui'

import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { toastSlice } from '../../store/toastSlice'

export const Toast = () => {
  const isOpen = useAppSelector(toastSlice.selectors.isOpen)
  const message = useAppSelector(toastSlice.selectors.message)
  const ctaCopy = useAppSelector(toastSlice.selectors.ctaCopy)
  const dispatch = useAppDispatch()

  return (
    <ToastSpitUi
      className="px-3 py-2 data-[state=closed]:animate-fade-out data-[state=open]:animate-slide-in-from-bottom"
      isOpen={isOpen}
      onOpenChange={(a: boolean) => dispatch(toastSlice.actions.setIsOpen(a))}
      viewportClassName="bottom-4 inset-x-0 flex items-center justify-center"
    >
      <div className="flex flex-col items-center gap-2 lg:flex-row">
        <span>{message}</span>
        {ctaCopy && (
          <Button
            className="border-none bg-gray-500 hover:bg-gray-500"
            onClick={() => dispatch(toastSlice.actions.setIsOpen(false))}
            size="sm"
            type="button"
          >
            {ctaCopy}
          </Button>
        )}
      </div>
    </ToastSpitUi>
  )
}
