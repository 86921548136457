import { ItemQuantityController } from '../../../components/ItemQuantityController/ItemQuantityController'
import { useAppDispatch } from '../../../hooks/store'
import { changeQuantityActioType } from '../../../models/cart'
import { MapSetupItem } from '../../../models/map'
import { cartChangeQuotableSeatQuantity } from '../../../store/cartSlice'

interface Props {
  remainingGlobal: number
  item: MapSetupItem
}
export const SetupSeatListItem = (props: Props) => {
  const dispatch = useAppDispatch()

  const dynamicKey = props.item.keyName

  const handleAddSeat = () => {
    dispatch(
      cartChangeQuotableSeatQuantity({
        actionType: changeQuantityActioType.ADD,
        dynamicKey,
      })
    )
  }

  const handleRemoveSeat = () => {
    dispatch(
      cartChangeQuotableSeatQuantity({
        actionType: changeQuantityActioType.REMOVE,
        dynamicKey,
      })
    )
  }
  return (
    <ItemQuantityController
      available={{
        global: props.remainingGlobal,
        group: props.item?.max,
        ticket: props.item.max,
      }}
      hasLimit={props.item.max >= 0}
      icon={props.item.icon}
      initialQuantity={props.item.d}
      key={props.item.keyName}
      minimumQuantity={props.item?.min}
      name={props.item.label}
      onAdd={() => handleAddSeat()}
      onRemove={() => handleRemoveSeat()}
    />
  )
}
