import * as rd from '@devexperts/remote-data-ts'
import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'
import { MapInitParam } from '@spiaggeit/map-canvas'
import { t } from 'i18next'

import { httpClient } from '../lib/http/HttpClient'
import {
  MapElementWithSectorId,
  MapResponse,
  MapSetupItem,
  MapSetupItemKeyName,
  Sector,
  SectorDetailType,
} from '../models/map'
import { MAP_CELL_SIZE, mapSetupElements } from '../utils/constants'
import { fromISOtoTimestamp } from '../utils/dateUtils'
import { getFormattedMapElement } from '../utils/getFormattedMapElement'
import { getHalfDayValueFromBookingPeriod } from '../utils/halfDay'

import { appSlice } from './appSlice'
import { RootState } from './configureStore'
import { createAppSlice } from './createAppSlice'
import { insertPeriodSlice } from './insertPeriodSlice'
import { licenseSlice } from './licenseSlice'

type MapInitOpts = MapInitParam['opts']

interface ExtendedMapInitOpts extends MapInitOpts {
  elements: MapElementWithSectorId[]
}

export type MapSliceState = {
  mapData: rd.RemoteData<string, Partial<ExtendedMapInitOpts>>
  selectedElementId?: string
  selectedElement: MapElementWithSectorId | null
  currentSector: Sector | null
  currentSectorDetails: Sector['details'] | null
  sectors: Sector[]
  selectedElementSetupData: {
    selectedElementSetupItems: MapSetupItem[]
    selectedElementSetupLimit: MapSetupItem | null
  }
}

const initialState: MapSliceState = {
  currentSector: null,
  currentSectorDetails: null,
  mapData: rd.initial,
  sectors: [],
  selectedElement: null,
  selectedElementSetupData: {
    selectedElementSetupItems: [],
    selectedElementSetupLimit: null,
  },
}

export const loadMapData = createAsyncThunk<
  Partial<MapInitParam['opts']>,
  void,
  { rejectValue: string; state: RootState }
>('map/loadMapData', async (_, thunkApi) => {
  const state = thunkApi.getState()
  const period = insertPeriodSlice.selectors.period(state)
  const bookingPeriod = insertPeriodSlice.selectors.bookingPeriod(state)
  const country = appSlice.selectors.country(state)
  const license = licenseSlice.selectors.license(state)

  if (!period || !period.end || !period.start) {
    return thunkApi.rejectWithValue('Period not defined')
  }

  const from = fromISOtoTimestamp(period.start)
  const to = fromISOtoTimestamp(period.end)
  const halfDay = getHalfDayValueFromBookingPeriod(bookingPeriod)

  try {
    const response = await httpClient.fetch<MapResponse>(
      `beaches/${license?.license}/map-v2?from=${from}&to=${to}&halfDay=${halfDay}&v2Payload=1`,
      country
    )

    if (response.status === 'success' && response.data) {
      const data = response.data.result
      return {
        background: data.background
          ? {
              height: data.background.height * MAP_CELL_SIZE,
              offset: {
                x: data.background.posX * MAP_CELL_SIZE,
                y: data.background.posY * MAP_CELL_SIZE,
              },
              url: data.background.url,
              width: data.background.width * MAP_CELL_SIZE,
            }
          : {
              height: 0,
              offset: {
                x: 0,
                y: 0,
              },
              url: null,
              width: 0,
            },
        elements: data.elements.map((element) =>
          getFormattedMapElement(element)
        ),
        size: {
          height: data.height,
          width: data.width,
        },
      }
    }

    throw new Error('Unexpected response structure')
  } catch (_error) {
    return thunkApi.rejectWithValue('Failed to fetch map data')
  }
})

export const loadSectors = createAsyncThunk<
  Sector[],
  void,
  { rejectValue: string; state: RootState }
>('map/loadSectors', async (_, thunkApi) => {
  const state = thunkApi.getState()
  const license = licenseSlice.selectors.license(state)
  const country = appSlice.selectors.country(state)

  try {
    const response = await httpClient.fetch<{ result: { sectors: Sector[] } }>(
      `beaches/${license?.license}/sectors`,
      country
    )

    if (response.status === 'success' && response.data) {
      const sectors = response.data.result.sectors
      return sectors
    }

    throw new Error('Unexpected response structure')
  } catch (_error) {
    return thunkApi.rejectWithValue('Failed to fetch sectors data')
  }
})

export const mapSlice = createAppSlice({
  extraReducers: (builder) => {
    builder
      .addCase(loadMapData.pending, (state) => {
        state.mapData = rd.pending
      })
      .addCase(
        loadMapData.fulfilled,
        (state, action: PayloadAction<Partial<MapInitParam['opts']>>) => {
          state.mapData = rd.success(action.payload)
        }
      )
      .addCase(loadMapData.rejected, (state, action) => {
        state.mapData = rd.failure(action.payload as string)
      })
      .addCase(loadSectors.pending, (state) => {
        state.sectors = []
      })
      .addCase(
        loadSectors.fulfilled,
        (state, action: PayloadAction<Sector[]>) => {
          state.sectors = action.payload
        }
      )
      .addCase(loadSectors.rejected, (state) => {
        state.sectors = []
      })
  },
  initialState: initialState satisfies MapSliceState as MapSliceState,
  name: 'map',
  reducers: (create) => ({
    selectMapElement: create.reducer(
      (state, action: PayloadAction<MapElementWithSectorId | null>) => {
        state.selectedElementId = action.payload?.id
      }
    ),
  }),
})

export const mapSelectors = {
  error: (state: RootState): string | null =>
    rd.fold<string, Partial<MapInitParam['opts']>, string | null>(
      () => null,
      () => null,
      (e) => e,
      () => null
    )(state.map.mapData),
  hasError: (state: RootState) => rd.isFailure(state.map.mapData),
  isLoading: (state: RootState) => rd.isPending(state.map.mapData),
  mapData: (state: RootState) => {
    if (rd.isFailure(state.map.mapData)) {
      return null
    } else if (rd.isSuccess(state.map.mapData)) {
      return state.map.mapData.value
    }
  },
  sectors: (state: RootState) => state.map.sectors,
  selectedElement: (state: RootState) => {
    if (rd.isFailure(state.map.mapData)) {
      return null
    } else if (rd.isSuccess(state.map.mapData)) {
      return state.map.mapData.value.elements?.find((element) => {
        return String(element.id) === String(state.map.selectedElementId)
      })
    }
  },
  selectedElementId: (state: RootState) => state.map.selectedElementId,
}

export const mapCurrentSectorSelector = createSelector(
  [mapSelectors.selectedElement, mapSelectors.sectors],
  (mapSelectedElement, mapSectors) => {
    return mapSectors?.find(
      (sector) => sector.header.id === mapSelectedElement?.sectorId
    )
  }
)

export const mapCurrentSectorDetailsSelector = createSelector(
  [mapCurrentSectorSelector],
  (mapCurrentSector) => {
    return mapCurrentSector?.details.filter(
      (item) => item.type === SectorDetailType.ONLINE
    )
  }
)

export const mapSelectedElementSetupDataSelector = createSelector(
  [mapCurrentSectorSelector],
  (mapCurrentSector) => {
    const sectorDetails = mapCurrentSector?.details.filter(
      (item) => item.type === SectorDetailType.ONLINE
    )
    if (!sectorDetails) return
    const groupedData = sectorDetails.reduce<Record<string, MapSetupItem>>(
      (acc, item) => {
        const keyName = item.keyName

        acc[keyName as keyof object] = {
          ...acc[keyName],
          icon: mapSetupElements[item?.keyName as keyof object]['icon'],
          keyName: item?.keyName as MapSetupItemKeyName,
          label: t(mapSetupElements[item?.keyName as keyof object]['label']),
          [item['keyType']]: item.keyValue,
          sectorId: item?.sectorId,
        }

        return acc
      },
      {}
    )
    const selectedElementSetupItems = Object.values(groupedData).filter(
      (item: MapSetupItem) =>
        item?.keyName !== MapSetupItemKeyName.COMBINED_LIMIT
    )

    const selectedElementSetupLimit =
      groupedData[MapSetupItemKeyName.COMBINED_LIMIT]
    return { selectedElementSetupItems, selectedElementSetupLimit }
  }
)
