import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { useDayDifference } from '../../../../hooks/useDayDifference'
import { Service, ServiceLimitType } from '../../../../models/service'
import { cartSlice } from '../../../../store/cartSlice'
import { mapSelectors } from '../../../../store/mapSlice'
import { servicesSlice } from '../../../../store/servicesSlice'
import { getServiceUpdatedQuantity } from '../../../../utils/getServiceUpdatedQuantity'

import { SetupServiceListItem } from './SetupServiceListItem'

export const SetupServiceList = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const selectedElement = useAppSelector(mapSelectors.selectedElement)
  const selectedElementQuotedSeatsAmount = useAppSelector(
    cartSlice.selectors.selectedElementQuotedSeatsAmount
  )
  const selectedElementQuotableProducts = useAppSelector(
    cartSlice.selectors.selectedElementQuotableProducts
  )
  const cartReservations = useAppSelector(cartSlice.selectors.cartReservations)

  const currentCartElementReservation = cartReservations.find(
    (reservation) => reservation.spotName === selectedElement?.name
  )
  const quoteReservations = useAppSelector(
    cartSlice.selectors.quoteReservations
  )
  const currentQuoteElementReservation = quoteReservations?.find(
    (reservation) => reservation.spotName === selectedElement?.name
  )

  const dayDifference = useDayDifference()
  const servicesData = useAppSelector(servicesSlice.selectors.data)
  const includedServices = servicesData?.includedServices
  const extraServices = servicesData?.extraServices

  useEffect(() => {
    if (!includedServices || !extraServices) return

    const finalizeServiceSelectedQuantity = (service: Service) => {
      const dynamicKey = `id${service.serviceId}`
      const previousQuantity =
        currentCartElementReservation?.services[dynamicKey] ||
        currentQuoteElementReservation?.services[dynamicKey] ||
        0

      const calculatedQuantity = getServiceUpdatedQuantity({
        dayDifference,
        selectedSeats: selectedElementQuotedSeatsAmount,
        service,
        serviceInCartQuantity:
          currentCartElementReservation?.services[dynamicKey],
        serviceInQuoteReservationQuantity:
          currentQuoteElementReservation?.services[dynamicKey],
      })

      const finalQuantity = Math.max(previousQuantity, calculatedQuantity)

      if (service.minimumLimitType === ServiceLimitType.NUMBER_OF_PIECES) {
        return Math.max(finalQuantity, selectedElementQuotedSeatsAmount)
      } else if (
        service.maximumLimitType === ServiceLimitType.NUMBER_OF_PIECES
      ) {
        return Math.min(finalQuantity, selectedElementQuotedSeatsAmount)
      }

      return finalQuantity
    }

    const newServicesState = [...includedServices, ...extraServices].reduce(
      (acc, service) => {
        const finalQuantity = finalizeServiceSelectedQuantity(service)

        if (finalQuantity > 0) {
          acc[`id${service.serviceId}`] = finalQuantity
        }

        return acc
      },
      {} as Record<string, number>
    )

    dispatch(
      cartSlice.actions.setSelectedElementQuotableProducts({
        ...selectedElementQuotableProducts,
        services: {
          ...selectedElementQuotableProducts.services,
          ...newServicesState,
        },
      })
    )
  }, [
    includedServices,
    extraServices,
    selectedElementQuotedSeatsAmount,
    dayDifference,
    currentCartElementReservation,
  ])

  if (!selectedElement) return null

  return (
    <>
      <div className="flex flex-col">
        {includedServices && includedServices.length > 0 && (
          <div className="flex flex-col gap-3 border-b border-solid py-3">
            <h3 className="font-bold text-primary">
              {t('map.elementDetail.includedServices')}
            </h3>
            {includedServices.map((service) => (
              <SetupServiceListItem key={service.serviceId} service={service} />
            ))}
          </div>
        )}

        {extraServices && extraServices.length > 0 && (
          <div className="flex flex-col gap-3 py-3">
            <h3 className="font-bold text-primary">
              {t('map.elementDetail.extraServices')}
            </h3>
            {extraServices.map((service) => (
              <SetupServiceListItem key={service.serviceId} service={service} />
            ))}
          </div>
        )}
      </div>
    </>
  )
}
