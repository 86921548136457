import { BookingPeriod } from '@spiaggeit/spit-datepicker'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PeriodSliceState } from '../store/insertPeriodSlice'

export const useTotalPriceLabel = ({
  bookingPeriod,
  period,
}: {
  bookingPeriod: PeriodSliceState['bookingPeriod']
  period: PeriodSliceState['period']
}) => {
  const { t } = useTranslation()

  return useMemo(() => {
    if (!period) return

    const startDate = DateTime.fromISO(period.start)
    const endDate = DateTime.fromISO(period.end)

    const dayDifference = endDate.diff(startDate, 'days').days + 1

    if (dayDifference > 1) {
      return t('map.elementDetail.multipleDaysTotalPrice', {
        days: String(dayDifference),
      })
    } else if (dayDifference === 1 && bookingPeriod === BookingPeriod.MORNING) {
      return t('map.elementDetail.morningTotalPrice')
    } else if (
      dayDifference === 1 &&
      bookingPeriod === BookingPeriod.AFTERNOON
    ) {
      return t('map.elementDetail.afternoonTotalPrice')
    }
    return t('map.elementDetail.singleDayTotalPrice')
  }, [period, bookingPeriod, t])
}
