import { Button, Dialog } from '@spiaggeit/spit-ui'
import { t } from 'i18next'
import { Dispatch, SetStateAction } from 'react'

import { useAppDispatch } from '../../../hooks/store'
import { cartSlice, cartSliceInitialState } from '../../../store/cartSlice'
import { mapSlice } from '../../../store/mapSlice'

export const DiscardChangesDialog = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const dispatch = useAppDispatch()

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} size="sm">
      <div className="flex flex-col p-4">
        <h3 className="text-primary">
          {t('map.elementDetail.discardChangesConfirmation')}
        </h3>
        <div className="flex flex-col gap-2 pt-8">
          <Button
            onClick={() => {
              setIsOpen(false)
              dispatch(mapSlice.actions.selectMapElement(null))
              dispatch(
                cartSlice.actions.setSelectedElementQuotableProducts(
                  cartSliceInitialState.selectedElementQuotableProducts
                )
              )
            }}
          >
            {t('map.elementDetail.discardChanges')}
          </Button>
          <Button
            onClick={() => {
              setIsOpen(false)
            }}
            variant="outline"
          >
            {t('map.elementDetail.cancelDiscardChanges')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
