import { Button, cn } from '@spiaggeit/spit-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Cart } from '../../../assets/icons/Cart'
import { ExclamationMark } from '../../../assets/icons/ExclamationMark'
import { Minus } from '../../../assets/icons/Minus'
import { Plus } from '../../../assets/icons/Plus'
import { useAppSelector } from '../../../hooks/store'
import { cartMapSpotsSelector, cartSlice } from '../../../store/cartSlice'
import { priceFormatter } from '../../../utils/price'
import { LegendDialog } from '../LegendDialog'
import { ServiceCostDialog } from '../SelectedElementDrawer/ServiceCostDialog'

import { CartContent } from './CartContent'

interface Props {
  zoomIn(): void
  zoomOut(): void
}

export const CartBottomDrawer = (props: Props) => {
  const { t } = useTranslation()
  const cartItems = useAppSelector(cartSlice.selectors.items)
  const cartTotal = useAppSelector(cartSlice.selectors.total)
  const mapSpots = useAppSelector(cartMapSpotsSelector)
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  return (
    <div className="fixed bottom-0 left-0 right-0 z-30 flex h-fit grow flex-col gap-3 rounded-tl-lg rounded-tr-lg bg-white p-4 shadow lg:rounded-none lg:px-32">
      <div
        className={cn('max-h-0 overflow-hidden lg:hidden', {
          'animate-contentShow max-h-none': popoverIsOpen,
        })}
      >
        <CartContent />
      </div>
      <div className="flex justify-between">
        <div className="hidden items-center gap-8 lg:flex">
          <div className="flex items-center gap-2">
            <button
              className="flex h-10 w-10 items-center justify-center rounded border border-gray-300"
              onClick={props.zoomOut}
            >
              <Minus />
            </button>
            <button
              className="flex h-10 w-10 items-center justify-center rounded border border-gray-300"
              onClick={props.zoomIn}
            >
              <Plus />
            </button>
          </div>
          <LegendDialog />
        </div>
        <div className="relative flex basis-full items-center justify-between gap-4 lg:basis-auto">
          {popoverIsOpen && mapSpots && mapSpots?.length >= 1
            ? (
                <div
                  className="absolute top-0 hidden w-[400px] translate-x-[-50%] translate-y-[-100%] rounded-lg bg-white px-4 drop-shadow-md after:absolute after:-bottom-0 
            after:left-1/2 after:h-0 after:w-0 after:-translate-x-1/2 after:translate-y-[100%] after:border-l-[16px] 
            after:border-r-[16px] after:border-t-[12px] 
            after:border-l-transparent after:border-r-transparent 
            after:border-t-white after:content-[''] lg:block"
                >
                  {<CartContent setPopoverIsOpen={setPopoverIsOpen} />}
                </div>
              )
            : null}
          <button
            aria-label={t('common.open')}
            className={cn('relative h-8 w-8', {
              'cursor-default': !mapSpots || mapSpots.length < 1,
            })}
            onClick={() => setPopoverIsOpen((prev) => !prev)}
          >
            <span className="absolute bottom-4 left-4 flex min-h-5 min-w-5 items-center justify-center rounded-circle bg-yellow-300 text-xs text-white">
              {Object.keys(cartItems).length}
            </span>
            <Cart />
          </button>
          <div className="flex flex-col">
            <span className="font-bold text-primary">
              {priceFormatter.format(cartTotal)}
            </span>
            <ServiceCostDialog
              trigger={
                <button aria-label={t('common.open')}>
                  <span className="inline-flex gap-1 text-sm text-secondary underline">
                    {t('tickets.summary.includedFees')}
                    <span className="text-base text-primary">
                      <ExclamationMark height={16} width={16} />
                    </span>
                  </span>
                </button>
              }
            />
          </div>
          <Button disabled={Object.values(cartItems).length === 0}>
            {t('common.proceed')}
          </Button>
        </div>
      </div>
    </div>
  )
}
