import { useTranslation } from 'react-i18next'

import { Checkmark } from '../../assets/icons/Checkmark'

interface Props {
  action: React.ReactElement
  isSoldOut: boolean
  list: string[]
  title: string
}

export const Card = (props: Props) => {
  const { t } = useTranslation()

  return (
    <div className="relative flex flex-col justify-between place-self-stretch overflow-hidden rounded-lg border border-gray-200 bg-white p-6 md:max-w-[320px]">
      {props.isSoldOut
        ? (
            <div className="absolute right-0 top-0 flex h-24 w-24 -translate-y-1/2 translate-x-1/2 rotate-45 items-end justify-center bg-red-600 pb-1 text-xs font-bold uppercase text-white shadow-md">
              {t('chooseProduct.card.soldOut')}
            </div>
          )
        : null}

      <div className="mb-6 space-y-6">
        <h4 className="text-lg font-bold">{props.title}</h4>

        <ul className="space-y-3">
          {props.list.map((item, index) => (
            <li className="flex space-x-2" key={index}>
              <Checkmark className="flex-none" /> <div>{item}</div>
            </li>
          ))}
        </ul>
      </div>

      {props.action}
    </div>
  )
}
