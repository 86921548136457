export const ROOT_PATH = '/:license'

const makePath = (paths: string[]) => `${ROOT_PATH}/${paths.join('/')}`

export const LANDING_KIOSK_PATH = makePath(['landingKiosk'])
export const INSERT_PERIOD_PATH = makePath(['insertPeriod'])
export const CHOOSE_PRODUCT_PATH = makePath(['chooseProduct'])
export const MAP_PATH = makePath(['insertMap'])
export const SECTOR_PATH = makePath(['insertSector'])
export const TICKETS_PATH = makePath(['tickets'])
export const PAYMENT_KIOSK_PATH = makePath(['paymentKiosk'])
export const SETTINGS_PATH = '/settings'
