import { useTranslation } from 'react-i18next'

interface Props {
  description: string
  isSoldOut: boolean
  icon: React.ReactElement
  onClick: VoidFunction
  title: string
}
export const KioskCard = (props: Props) => {
  const { t } = useTranslation()

  return (
    <div
      className="relative flex flex-1 cursor-pointer flex-col justify-between overflow-hidden rounded-md border border-gray-200 bg-white px-3 py-8 shadow-sm"
      onClick={() => {
        if (props.isSoldOut) return
        props.onClick()
      }}
    >
      {props.isSoldOut
        ? (
            <div className="absolute right-0 top-0 flex h-24 w-24 -translate-y-1/2 translate-x-1/2 rotate-45 items-end justify-center bg-red-600 pb-1 text-xs font-bold uppercase text-white shadow-md">
              {t('chooseProduct.card.soldOut')}
            </div>
          )
        : null}

      <div className="flex flex-col items-center space-y-2 text-center">
        {props.icon}

        <h4 className="mt-4 font-bold">{props.title}</h4>

        <div className="mt-px text-xs">{props.description}</div>
      </div>
    </div>
  )
}
