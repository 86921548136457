import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath, Link, useSearchParams } from 'react-router-dom'

import { INSERT_PERIOD_PATH } from '../../app/router/paths'
import { PoweredBy } from '../../assets/icons/PoweredBy'
import { LanguageSwitcher } from '../../components/LanguageSwitcher'
import { useAppSelector } from '../../hooks/store'
import { licenseSlice } from '../../store/licenseSlice'

export const LandingKioskRoute = () => {
  const license = useAppSelector(licenseSlice.selectors.license)
  const { i18n, t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ch: 'kiosk',
    })
  }, [searchParams, i18n.language])

  if (!license) return null

  return (
    <div className="flex flex-1 flex-col py-6">
      <div className="flex w-full flex-initial items-center justify-between px-6">
        <img
          className="w-28"
          src={`https://img.spiagge.it/logo/${license.license}.png`}
        />

        <LanguageSwitcher />
      </div>

      <Link
        className="flex flex-1 flex-col items-center justify-center px-6"
        to={`${generatePath(INSERT_PERIOD_PATH, { license: license.license })}?${searchParams.toString()}`}
      >
        <div>
          <h2 className="text-center text-4xl">
            <Trans i18nKey="kiosk.landing.title" />
          </h2>

          <div className="mt-16 flex flex-col items-center justify-center">
            <img alt="Kiosk" className="w-16" src="/images/kiosk/start.png" />

            <p className="text-center text-xl font-bold">
              {t('kiosk.landing.start')}
            </p>
          </div>
        </div>
      </Link>

      <div className="flex items-center justify-center">
        <PoweredBy />
      </div>
    </div>
  )
}
