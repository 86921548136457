export const Edit = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.1665 15H7.69984C7.80951 15.0006 7.91823 14.9796 8.01976 14.9381C8.12129 14.8967 8.21364 14.8356 8.2915 14.7583L14.0582 8.98333L16.4248 6.66667C16.5029 6.5892 16.5649 6.49703 16.6072 6.39548C16.6496 6.29393 16.6713 6.18501 16.6713 6.075C16.6713 5.96499 16.6496 5.85607 16.6072 5.75452C16.5649 5.65297 16.5029 5.5608 16.4248 5.48333L12.8915 1.90833C12.814 1.83023 12.7219 1.76823 12.6203 1.72592C12.5188 1.68362 12.4098 1.66183 12.2998 1.66183C12.1898 1.66183 12.0809 1.68362 11.9794 1.72592C11.8778 1.76823 11.7856 1.83023 11.7082 1.90833L9.35817 4.26667L3.57484 10.0417C3.4976 10.1195 3.4365 10.2119 3.39503 10.3134C3.35356 10.4149 3.33254 10.5237 3.33317 10.6333V14.1667C3.33317 14.3877 3.42097 14.5996 3.57725 14.7559C3.73353 14.9122 3.94549 15 4.1665 15ZM12.2998 3.675L14.6582 6.03333L13.4748 7.21667L11.1165 4.85833L12.2998 3.675ZM4.99984 10.975L9.9415 6.03333L12.2998 8.39167L7.35817 13.3333H4.99984V10.975ZM17.4998 16.6667H2.49984C2.27882 16.6667 2.06686 16.7545 1.91058 16.9107C1.7543 17.067 1.6665 17.279 1.6665 17.5C1.6665 17.721 1.7543 17.933 1.91058 18.0893C2.06686 18.2455 2.27882 18.3333 2.49984 18.3333H17.4998C17.7209 18.3333 17.9328 18.2455 18.0891 18.0893C18.2454 17.933 18.3332 17.721 18.3332 17.5C18.3332 17.279 18.2454 17.067 18.0891 16.9107C17.9328 16.7545 17.7209 16.6667 17.4998 16.6667Z"
        fill="currentColor"
      />
    </svg>
  )
}
