import { Button } from '@spiaggeit/spit-ui'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowRight } from '../../../assets/icons/ArrowRight'
import { Cart } from '../../../assets/icons/Cart'
import { ExclamationMark } from '../../../assets/icons/ExclamationMark'
import { Info } from '../../../assets/icons/Info'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { useTotalPriceLabel } from '../../../hooks/useTotalPriceLabel'
import {
  cartSlice,
  cartSliceInitialState,
  QuoteResponse,
} from '../../../store/cartSlice'
import { insertPeriodSlice } from '../../../store/insertPeriodSlice'
import { licenseSlice } from '../../../store/licenseSlice'
import {
  mapSelectedElementSetupDataSelector,
  mapSelectors,
  mapSlice,
} from '../../../store/mapSlice'
import { FALLBACK_LICENSE_BOOKING_USER_UMBRELLAS_LIMIT } from '../../../utils/constants'
import { getFormattedQuoteKey } from '../../../utils/getFormattedQuoteKey'
import { priceFormatter } from '../../../utils/price'
import { RemoveElementFromCartDialog } from '../RemoveElementFromCartDialog'

import { ServiceCostDialog } from './ServiceCostDialog'

export const SetupCartActions = ({
  handleClose,
}: {
  handleClose: () => void
}) => {
  const { t } = useTranslation()
  const period = useAppSelector(insertPeriodSlice.selectors.period)
  const selectedElement = useAppSelector(mapSelectors.selectedElement)
  const bookingPeriod = useAppSelector(
    insertPeriodSlice.selectors.bookingPeriod
  )
  const license = useAppSelector(licenseSlice.selectors.license)
  const cartItems = useAppSelector(cartSlice.selectors.items)
  const cartItemsAmount = Object.values(cartItems).length
  const cartItemsLimit =
    license?.bookingUserUmbrellasLimit ||
    FALLBACK_LICENSE_BOOKING_USER_UMBRELLAS_LIMIT

  const dispatch = useAppDispatch()
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const quote = useAppSelector(cartSlice.selectors.quote)

  const totalPriceLabel = useTotalPriceLabel({ bookingPeriod, period })

  const isElementAddedToCart = useMemo(() => {
    return Object.keys(cartItems).includes(`${selectedElement?.id}`)
  }, [cartItems, selectedElement])

  const isQuotePending = useAppSelector(cartSlice.selectors.isQuotePending)

  const setupData = useAppSelector(mapSelectedElementSetupDataSelector)
  const selectedElementSetupLimit = setupData?.selectedElementSetupLimit
  const selectedElementQuotedSeatsAmount = useAppSelector(
    cartSlice.selectors.selectedElementQuotedSeatsAmount
  )
  const [areCartButtonsEnabled, setAreCartButtonsEnabled] = useState(false)

  useEffect(() => {
    const isMinimumSeatsAmountSelected = selectedElementSetupLimit?.min
      ? selectedElementQuotedSeatsAmount >= selectedElementSetupLimit.min
      : selectedElementQuotedSeatsAmount > 0

    if (isQuotePending || !isMinimumSeatsAmountSelected) {
      setAreCartButtonsEnabled(false)
    } else setAreCartButtonsEnabled(true)
  }, [
    isQuotePending,
    selectedElementSetupLimit,
    selectedElementQuotedSeatsAmount,
    setAreCartButtonsEnabled,
  ])

  if (!selectedElement || typeof quote === 'string') return null

  const quoteReservations = useAppSelector(
    cartSlice.selectors.quoteReservations
  )

  const onSaveEdit = () => {
    if (!quoteReservations) return
    dispatch(cartSlice.actions.decrease({ id: Number(selectedElement.id) }))

    dispatch(
      cartSlice.actions.add({
        id: Number(selectedElement?.id),
      })
    )

    dispatch(mapSlice.actions.selectMapElement(null))
    dispatch(
      cartSlice.actions.setSelectedElementQuotableProducts(
        cartSliceInitialState.selectedElementQuotableProducts
      )
    )
  }

  const cartReservations = useAppSelector(cartSlice.selectors.cartReservations)

  return (
    <div className="border-t border-solid border-gray-200 p-4 lg:mt-auto">
      {quote?.reservationPrices &&
        Object.keys(quote?.reservationPrices).map((key) => (
          <div
            className="flex items-center justify-between gap-1 text-sm text-secondary"
            key={key}
          >
            <span>{getFormattedQuoteKey(key)}</span>
            <span>
              {priceFormatter.format(
                (quote?.reservationPrices &&
                  quote?.reservationPrices[
                    key as keyof QuoteResponse['result']['reservationPrices']
                  ]) ||
                  0
              )}
            </span>
          </div>
        ))}
      <div className="flex items-center justify-between gap-1 text-sm text-secondary">
        <ServiceCostDialog
          trigger={
            <button
              aria-label={t('common.open')}
              className="flex items-center gap-1 underline"
            >
              {t('map.elementDetail.serviceCost.title')}
              <span className="text-base text-primary">
                <ExclamationMark height={16} width={16} />
              </span>
            </button>
          }
        />

        <span>{priceFormatter.format(quote.fees)}</span>
      </div>
      {quote?.offer && quote.offer > 0
        ? (
            <div className="flex items-center justify-between gap-1 text-sm text-secondary">
              <span> {t('map.elementDetail.offer')}</span>
              <span>-{priceFormatter.format(quote.offer)}</span>
            </div>
          )
        : null}

      <div className="flex items-center justify-between gap-1 text-lg font-bold text-primary">
        <span>{totalPriceLabel}</span>
        <span>{priceFormatter.format(quote.totalPrice)}</span>
      </div>
      {isElementAddedToCart
        ? (
            <div className="mt-4 flex flex-col gap-2 lg:flex-row">
              <Button
                className="lg:basis-[50%]"
                color="error"
                disabled={!areCartButtonsEnabled}
                onClick={() => setDialogIsOpen(true)}
                variant="outline"
              >
                {t('map.elementDetail.removeFromCart')}
              </Button>
              <Button
                className="lg:basis-[50%]"
                disabled={!areCartButtonsEnabled}
                onClick={() => onSaveEdit()}
              >
                {t('map.elementDetail.saveEdit')}
              </Button>
            </div>
          )
        : (
            <>
              {cartItemsAmount === cartItemsLimit
                ? (
                    <div className="mt-4 flex items-center gap-1 rounded-md border border-red-100 bg-red-25 p-1">
                      <Info className="shrink-0 text-red-500" />
                      <span className="whitespace-normal text-sm">
                        {t('map.errors.mapSpotsLimitExceeded')}
                      </span>
                    </div>
                  )
                : (
                    <div className="flex flex-col gap-2 pt-4">
                      <Button
                        color="primary"
                        disabled={!areCartButtonsEnabled}
                        onClick={() => {
                          if (!selectedElement) return
                          dispatch(
                            cartSlice.actions.add({
                              id: Number(selectedElement?.id),
                            })
                          )
                          handleClose()
                        }}
                        variant="outline"
                      >
                        {t('map.elementDetail.addToCart')}
                        <Cart />
                      </Button>
                      <Button
                        disabled={!cartReservations || cartReservations?.length < 1}
                      >
                        {t('map.elementDetail.finishBooking')}
                        <ArrowRight />
                      </Button>
                    </div>
                  )}
            </>
          )}
      <RemoveElementFromCartDialog
        element={selectedElement}
        isOpen={dialogIsOpen}
        setIsOpen={setDialogIsOpen}
      />
    </div>
  )
}
