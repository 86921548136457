import { Dialog } from '@spiaggeit/spit-ui'
import { ReactNode } from 'react'
import FocusLock from 'react-focus-lock'
import { useTranslation } from 'react-i18next'

export const ServiceCostDialog = ({ trigger }: { trigger: ReactNode }) => {
  const { t } = useTranslation()
  return (
    <FocusLock>
      <Dialog
        mobileMode="bottomSheet"
        scrollContent
        title={t('map.elementDetail.serviceCost.title')}
        trigger={trigger}
      >
        <div className="p-4 text-base text-secondary">
          <p className="mb-2">
            {t('map.elementDetail.serviceCost.firstParagraph')}
          </p>
          <h3 className="font-bold">
            {t('map.elementDetail.serviceCost.secondParagraphTitle')}
          </h3>
          <p>{t('map.elementDetail.serviceCost.secondParagraphContent')}</p>
        </div>
      </Dialog>
    </FocusLock>
  )
}
